import React, { useState } from 'react';
import { FileUpload } from '../components/Dashboard/FileUpload';
import { LiveMeetingAssistant } from '../components/Meeting/LiveMeetingAssistant';
import { MeetingRecorder } from '../components/Meeting/MeetingRecorder';
import { Tabs } from '../components/ui/Tabs';
import { TranscriptionList } from '../components/Dashboard/TranscriptionList';
import type { Transcription } from '../types';

export function Transcribe() {
  const [activeTab, setActiveTab] = useState('upload');
  const [transcriptions, setTranscriptions] = useState<Transcription[]>([]);
  const [isRecordingActive, setIsRecordingActive] = useState(false);
  const [isLiveAssistantActive, setIsLiveAssistantActive] = useState(false);

  const handleTranscriptionComplete = (transcription: Transcription) => {
    setTranscriptions(prev => [transcription, ...prev]);
  };

  const handleTabChange = (tabId: string) => {
    if ((isRecordingActive && tabId !== 'record') || 
        (isLiveAssistantActive && tabId !== 'live')) {
      return; // Prevent tab change if session is active
    }
    setActiveTab(tabId);
  };

  const handleDelete = async (id: string) => {
    setTranscriptions(prev => prev.filter(t => t.id !== id));
  };

  const handleRetry = async (id: string) => {
    const transcription = transcriptions.find(t => t.id === id);
    if (transcription?.originalFile) {
      const newTranscription = await uploadAudioForTranscription({
        file: transcription.originalFile
      });
      setTranscriptions(prev => [
        newTranscription,
        ...prev.filter(t => t.id !== id)
      ]);
    }
  };

  const tabs = [
    { id: 'upload', label: 'Upload Audio' },
    { id: 'record', label: 'Record Meeting' },
    { id: 'live', label: 'Live Meeting Assistant' }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Audio Transcription</h1>
      
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChange={handleTabChange}
        disabled={isRecordingActive || isLiveAssistantActive}
      />

      <div className="mt-6 space-y-8">
        {activeTab === 'upload' && (
          <>
            <FileUpload onTranscriptionComplete={handleTranscriptionComplete} />
            <TranscriptionList 
              transcriptions={transcriptions} 
              onDelete={handleDelete}
              onRetry={handleRetry}
            />
          </>
        )}
        {activeTab === 'record' && (
          <>
            <MeetingRecorder 
              maxDuration={900}
              onTranscriptionComplete={handleTranscriptionComplete}
              onSessionChange={setIsRecordingActive}
              isOtherSessionActive={isLiveAssistantActive}
            />
            <TranscriptionList 
              transcriptions={transcriptions}
              onDelete={handleDelete}
              onRetry={handleRetry}
            />
          </>
        )}
        {activeTab === 'live' && (
          <LiveMeetingAssistant 
            onTranscriptionComplete={handleTranscriptionComplete}
            isOtherSessionActive={isRecordingActive}
            onSessionChange={setIsLiveAssistantActive}
          />
        )}
      </div>
    </div>
  );
}