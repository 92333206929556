import React, { useState, useEffect } from 'react';
import { 
  Brain, Share2, Mail, Linkedin, Facebook, Calendar, Clock, 
  AlertCircle, CheckCircle, XCircle, Bot, FileText, Download,
  Info, BarChart
} from 'lucide-react';
import type { Agent, SwarmResult } from '../../types';

interface SwarmAgent {
  id: string;
  name: string;
  status: 'queued' | 'gathering' | 'analyzing' | 'completed' | 'error';
  progress: number;
  findings: string[];
}

interface SwarmProgress {
  totalAgents: number;
  activeAgents: number;
  completedAgents: number;
  failedAgents: number;
  overallProgress: number;
  stage: 'initializing' | 'gathering' | 'analyzing' | 'completing';
}

export function AgentSwarm() {
  const [swarmAgents, setSwarmAgents] = useState<SwarmAgent[]>([]);
  const [swarmProgress, setSwarmProgress] = useState<SwarmProgress>({
    totalAgents: 0,
    activeAgents: 0,
    completedAgents: 0,
    failedAgents: 0,
    overallProgress: 0,
    stage: 'initializing'
  });

  const updateAgentProgress = (agentId: string, progress: number) => {
    setSwarmAgents(prev => prev.map(agent => 
      agent.id === agentId ? { ...agent, progress } : agent
    ));
  };

  const addFinding = (agentId: string, finding: string) => {
    setSwarmAgents(prev => prev.map(agent => 
      agent.id === agentId ? { 
        ...agent, 
        findings: [...agent.findings, finding]
      } : agent
    ));
  };

  const calculateOverallProgress = (agents: SwarmAgent[]) => {
    const completed = agents.filter(a => a.status === 'completed').length;
    const total = agents.length;
    return Math.round((completed / total) * 100);
  };

  const getStatusColor = (status: SwarmAgent['status']) => {
    switch (status) {
      case 'queued': return 'text-gray-500';
      case 'gathering': return 'text-blue-500';
      case 'analyzing': return 'text-yellow-500';
      case 'completed': return 'text-green-500';
      case 'error': return 'text-red-500';
      default: return 'text-gray-500';
    }
  };

  const getProgressBarColor = (status: SwarmAgent['status']) => {
    switch (status) {
      case 'gathering': return 'bg-blue-500';
      case 'analyzing': return 'bg-yellow-500';
      case 'completed': return 'bg-green-500';
      case 'error': return 'bg-red-500';
      default: return 'bg-gray-500';
    }
  };

  return (
    <div className="space-y-6">
      {/* Overall Progress */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Swarm Progress</h2>
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-500">
              {swarmProgress.completedAgents}/{swarmProgress.totalAgents} Agents Complete
            </span>
            <span className="text-sm text-gray-500">
              {swarmProgress.overallProgress}% Overall Progress
            </span>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4 mb-6">
          <div className="bg-blue-50 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <Brain className="w-5 h-5 text-blue-500" />
              <span className="text-lg font-semibold text-blue-700">
                {swarmProgress.totalAgents}
              </span>
            </div>
            <p className="text-sm text-blue-600 mt-1">Total Agents</p>
          </div>

          <div className="bg-yellow-50 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <BarChart className="w-5 h-5 text-yellow-500" />
              <span className="text-lg font-semibold text-yellow-700">
                {swarmProgress.activeAgents}
              </span>
            </div>
            <p className="text-sm text-yellow-600 mt-1">Active Agents</p>
          </div>

          <div className="bg-green-50 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <CheckCircle className="w-5 h-5 text-green-500" />
              <span className="text-lg font-semibold text-green-700">
                {swarmProgress.completedAgents}
              </span>
            </div>
            <p className="text-sm text-green-600 mt-1">Completed</p>
          </div>

          <div className="bg-red-50 p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <AlertCircle className="w-5 h-5 text-red-500" />
              <span className="text-lg font-semibold text-red-700">
                {swarmProgress.failedAgents}
              </span>
            </div>
            <p className="text-sm text-red-600 mt-1">Failed</p>
          </div>
        </div>

        <div className="space-y-4">
          {swarmAgents.map((agent) => (
            <div key={agent.id} className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-3">
                  <Bot className={`w-5 h-5 ${getStatusColor(agent.status)}`} />
                  <span className="font-medium text-gray-900">{agent.name}</span>
                </div>
                <span className={`text-sm capitalize ${getStatusColor(agent.status)}`}>
                  {agent.status}
                </span>
              </div>

              <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
                <div
                  className={`h-2 rounded-full transition-all duration-500 ${getProgressBarColor(agent.status)}`}
                  style={{ width: `${agent.progress}%` }}
                />
              </div>

              {agent.findings.length > 0 && (
                <div className="mt-2 space-y-1">
                  {agent.findings.map((finding, index) => (
                    <p key={index} className="text-sm text-gray-600">
                      • {finding}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}