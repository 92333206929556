import React from 'react';
import { Tag } from 'lucide-react';

interface TopicModelingProps {
  topics: string[];
}

export function TopicModeling({ topics }: TopicModelingProps) {
  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-2">
        {topics.map((topic, index) => (
          <div
            key={index}
            className="flex items-center space-x-1 px-3 py-1 bg-indigo-50 text-indigo-700 rounded-full"
          >
            <Tag className="w-4 h-4" />
            <span>{topic}</span>
          </div>
        ))}
      </div>
    </div>
  );
}