import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UsageMetrics {
  transcriptionsUsed: number;
  transcriptionsLimit: number;
  hoursUsed: number;
  hoursLimit: number;
  lastUpdated: string;
}

interface UsageState extends UsageMetrics {
  updateUsage: (metrics: Partial<UsageMetrics>) => void;
  incrementTranscription: (duration: number) => void;
  resetUsage: () => void;
  formatHoursUsed: () => string;
}

export const useUsageStore = create<UsageState>()(
  persist(
    (set, get) => ({
      transcriptionsUsed: 0,
      transcriptionsLimit: 100,
      hoursUsed: 0,
      hoursLimit: 10,
      lastUpdated: new Date().toISOString(),
      
      updateUsage: (metrics) => set((state) => ({
        ...state,
        ...metrics,
        lastUpdated: new Date().toISOString()
      })),

      incrementTranscription: (duration) => set((state) => ({
        ...state,
        transcriptionsUsed: state.transcriptionsUsed + 1,
        hoursUsed: state.hoursUsed + (duration / 3600), // Convert seconds to hours
        lastUpdated: new Date().toISOString()
      })),

      resetUsage: () => set({
        transcriptionsUsed: 0,
        hoursUsed: 0,
        lastUpdated: new Date().toISOString()
      }),

      formatHoursUsed: () => {
        const hours = Math.floor(get().hoursUsed);
        const minutes = Math.round((get().hoursUsed - hours) * 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      }
    }),
    {
      name: 'audio-transcribe-usage'
    }
  )
);