import React from 'react';
import { useLocation } from 'react-router-dom';
import { Mail } from 'lucide-react';

export function VerifyEmail() {
  const location = useLocation();
  const email = location.state?.email;

  return (
    <div className="max-w-md mx-auto text-center py-12">
      <Mail className="w-12 h-12 text-indigo-600 mx-auto mb-4" />
      <h1 className="text-3xl font-bold text-gray-900 mb-4">Check your email</h1>
      <p className="text-gray-600 mb-8">
        We sent a verification link to{' '}
        <span className="font-medium text-gray-900">{email}</span>
      </p>
      <p className="text-sm text-gray-500">
        Please click the link in the email to verify your account. If you don't see it,
        check your spam folder.
      </p>
    </div>
  );
}