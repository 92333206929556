import OpenAI from 'openai';

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_URL;
const SAMPLE_RATE = 16000;

export class RealtimeAudioService {
  private mediaRecorder: MediaRecorder | null = null;
  private audioContext: AudioContext | null = null;
  private stream: MediaStream | null = null;
  private isProcessing: boolean = false;
  private openai: OpenAI;

  constructor(
    private onTranscript: (text: string) => void,
    private onError: (error: string) => void,
    private onStateChange: (isListening: boolean) => void
  ) {
    this.openai = new OpenAI({
      apiKey: import.meta.env.VITE_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });
  }

  async startListening(): Promise<void> {
    if (this.isProcessing) return;

    try {
      // Initialize audio context and stream
      this.audioContext = new AudioContext({
        sampleRate: SAMPLE_RATE,
        latencyHint: 'interactive'
      });

      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          sampleRate: SAMPLE_RATE,
          channelCount: 1
        }
      });

      // Create and configure media recorder
      this.mediaRecorder = new MediaRecorder(this.stream, {
        mimeType: 'audio/webm'
      });

      let chunks: Blob[] = [];

      this.mediaRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
          
          // Process chunks when they reach a certain size
          if (chunks.length >= 5) {
            const audioBlob = new Blob(chunks, { type: 'audio/webm' });
            await this.processAudioChunk(audioBlob);
            chunks = []; // Clear processed chunks
          }
        }
      };

      this.mediaRecorder.onerror = (event) => {
        this.onError('MediaRecorder error: ' + event.error.message);
        this.cleanup();
      };

      // Start recording in chunks
      this.mediaRecorder.start(1000); // Collect data every second
      this.isProcessing = true;
      this.onStateChange(true);

    } catch (error) {
      this.onError(error instanceof Error ? error.message : 'Failed to start recording');
      this.cleanup();
    }
  }

  async stopListening(): Promise<void> {
    try {
      if (this.mediaRecorder?.state === 'recording') {
        this.mediaRecorder.stop();
      }
    } catch (error) {
      this.onError(error instanceof Error ? error.message : 'Failed to stop recording');
    } finally {
      this.cleanup();
    }
  }

  private cleanup(): void {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }

    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }

    this.mediaRecorder = null;
    this.isProcessing = false;
    this.onStateChange(false);
  }

  private async processAudioChunk(audioBlob: Blob): Promise<void> {
    try {
      const file = new File([audioBlob], 'audio.webm', { type: 'audio/webm' });
      
      const response = await this.openai.audio.transcriptions.create({
        file,
        model: 'whisper-1',
        language: 'en',
        response_format: 'text'
      });

      if (response) {
        this.onTranscript(response);
      }
    } catch (error) {
      console.error('Transcription error:', error);
      // Don't stop the recording on transcription errors, just log them
      this.onError('Transcription error: ' + (error instanceof Error ? error.message : 'Unknown error'));
    }
  }
}