import React from 'react';

export function Privacy() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-4">Privacy Statement</h1>
      <p className="text-sm text-gray-600 mb-8">Effective Date: 28/08/2024</p>

      <div className="prose prose-lg max-w-none space-y-8">
        <section>
          <h2 className="text-2xl font-bold text-gray-900">Introduction</h2>
          <p>Your privacy is essential to Audioscribe.today, a service operated by CyberResilience.Pro, AI Media Concept Publishers Division (referred to as "We," "Us," "Our," or the "Company"). This Privacy Statement explains how We collect, use, store, and protect Your personal information when You use Our website and Services (collectively, the "Services"). By using the Services, You consent to the data practices outlined in this Privacy Statement. If You do not agree with this Privacy Statement, please discontinue use of the Services.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">1. Scope and Applicability</h2>
          <p>This Privacy Statement applies to the personal data We collect through Audioscribe.today and any related services or interactions. It does not apply to third-party websites, products, or services that We may link to or that may link to Our Services, and which are governed by their own privacy policies.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">2. Data Controller and Compliance</h2>
          <p>CyberResilience.Pro, AI Media Concept Publishers Division, is the data controller responsible for Your personal data in accordance with the General Data Protection Regulation (GDPR) and the Dutch General Data Protection Regulation Implementation Act (UAVG). Our servers and data processing facilities are located within the EU-West Region.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">3. Data We Collect</h2>
          <p>We collect various types of data depending on Your interactions with Our Services. The types of data We may collect include:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Personal Identification Data:</strong> This may include Your name, email address, and other identifiers necessary for account creation, communication, and service provision.</li>
            <li><strong>Usage Data:</strong> We may collect information on how You use and interact with Our Services, including IP addresses, device information, browser types, access times, pages viewed, and other analytical data.</li>
            <li><strong>Content Data:</strong> If You use Our transcription or AI-driven services, We may collect audio, text, and other media files as part of Our Services. This data is processed for the purpose of providing the requested Services and is securely stored and managed.</li>
            <li><strong>Cookies and Tracking Technologies:</strong> We use cookies, web beacons, and similar technologies to enhance and customize Your experience, track usage patterns, and analyze website performance.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">4. How We Use Your Data</h2>
          <p>The data We collect is used solely for legitimate purposes and in accordance with applicable laws, including GDPR. Specifically, We may use Your data to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Provide Services:</strong> Process and deliver Your requested Services, including transcriptions and automated content generation.</li>
            <li><strong>Improve Our Services:</strong> Analyze user behavior, usage trends, and feedback to enhance, personalize, and optimize Our Services.</li>
            <li><strong>Communication:</strong> Send administrative notifications, service updates, and important information regarding Your account and interactions with the Services.</li>
            <li><strong>Compliance and Security:</strong> Protect the security, integrity, and legal rights of Our Services, Users, and systems.</li>
            <li><strong>Legal Requirements:</strong> Meet Our obligations under applicable law or respond to lawful requests from governmental or regulatory bodies.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">5. Legal Basis for Data Processing</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Consent:</strong> By using Our Services, You consent to the processing of Your personal data as described in this Privacy Statement.</li>
            <li><strong>Contractual Necessity:</strong> Certain data processing activities are necessary to provide You with the requested Services.</li>
            <li><strong>Legitimate Interests:</strong> We may process data for purposes related to the improvement of Our Services, fraud prevention, and network security.</li>
            <li><strong>Legal Obligations:</strong> We may process data to comply with legal requirements and obligations under Dutch and EU law.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">6. Data Sharing and Disclosure</h2>
          <p>We do not sell or rent Your personal data. However, We may share Your data in the following circumstances:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Service Providers:</strong> We may share Your information with trusted third-party providers who assist in providing, maintaining, and improving Our Services.</li>
            <li><strong>Legal Compliance:</strong> We may disclose Your data when required by law or legal process.</li>
            <li><strong>Corporate Transactions:</strong> Your data may be transferred as part of a business transaction.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">7. International Data Transfers</h2>
          <p>While Our data processing facilities and servers are located in the EU-West Region, certain service providers may operate outside the European Economic Area (EEA). We ensure that data transfers comply with GDPR by implementing appropriate safeguards.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">8. Data Retention</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Account Data:</strong> Retained as long as Your account is active.</li>
            <li><strong>Content Data:</strong> Stored only for the duration required to complete the Service.</li>
            <li><strong>Usage Data:</strong> Aggregated and anonymized data may be retained for analytical purposes.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">9. Security Measures</h2>
          <p>We implement industry-standard security measures to safeguard Your personal data from unauthorized access, disclosure, alteration, and destruction. These measures include encrypted data transmission, access controls, and regular security assessments.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">10. Your Rights under GDPR</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Right of Access</li>
            <li>Right to Rectification</li>
            <li>Right to Erasure</li>
            <li>Right to Restrict Processing</li>
            <li>Right to Data Portability</li>
            <li>Right to Object</li>
            <li>Right to Lodge a Complaint</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">11. Contact Information</h2>
          <p>If You have any questions, concerns, or requests regarding this Privacy Statement or Our data practices, please contact Us at:</p>
          <p className="font-medium mt-2">Email: terms@audioscribe.today</p>
          <p className="font-medium">Mailing Address: CyberResilience.Pro, AI Media Concept Publishers Division</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">12. Changes to this Privacy Statement</h2>
          <p>We reserve the right to modify this Privacy Statement at any time. Any changes will be posted on this page and are effective immediately upon posting. We encourage You to review this Privacy Statement periodically. Your continued use of the Services after any modifications signifies Your acceptance of the updated terms.</p>
        </section>
      </div>
    </div>
  );
}