import { Transcription } from '../types';

export async function exportToWord(transcription: Transcription, author: string): Promise<void> {
  // Create Word document content in HTML format
  const content = `
    <html xmlns:w="urn:schemas-microsoft-com:office:word">
      <head>
        <meta charset="utf-8">
        <title>${transcription.originalFileName || 'Transcript Analysis'}</title>
        <style>
          body { font-family: 'Calibri', sans-serif; }
          h1 { color: #333366; }
          h2 { color: #4F4F4F; margin-top: 20px; }
          .section { margin: 20px 0; }
          .metadata { color: #666; font-size: 11pt; }
          .topic { background: #F0F0F0; padding: 5px 10px; margin: 2px; display: inline-block; }
          .action-item { margin: 10px 0; padding-left: 20px; }
          .sentiment { color: #666; font-style: italic; }
        </style>
      </head>
      <body>
        <h1>${transcription.originalFileName || 'Transcript Analysis'}</h1>
        
        <div class="metadata">
          <p>Author: ${author}</p>
          <p>Date: ${new Date(transcription.createdAt).toLocaleDateString()}</p>
          <p>Duration: ${Math.round(transcription.duration / 60)} minutes</p>
          <p>Word Count: ${transcription.wordCount?.toLocaleString()}</p>
          <p>Language: ${transcription.languageInfo?.name || transcription.language}</p>
        </div>

        ${transcription.topics?.length ? `
          <div class="section">
            <h2>Topics</h2>
            ${transcription.topics.map(topic => 
              `<span class="topic">${topic}</span>`
            ).join(' ')}
          </div>
        ` : ''}

        <div class="section">
          <h2>Transcript</h2>
          ${transcription.transcript?.split('\n').map(para => 
            `<p>${para}</p>`
          ).join('') || ''}
        </div>

        ${transcription.enrichedContent?.meetingMinutes ? `
          <div class="section">
            <h2>Meeting Minutes</h2>
            ${transcription.enrichedContent.meetingMinutes}
          </div>
        ` : ''}

        ${transcription.enrichedContent?.keyDecisions?.length ? `
          <div class="section">
            <h2>Key Decisions</h2>
            <ul>
              ${transcription.enrichedContent.keyDecisions.map(decision => 
                `<li>${decision}</li>`
              ).join('')}
            </ul>
          </div>
        ` : ''}

        ${transcription.keyPhrases?.actionItems?.length ? `
          <div class="section">
            <h2>Action Items</h2>
            <ul>
              ${transcription.keyPhrases.actionItems.map(item => 
                `<li class="action-item">${item}</li>`
              ).join('')}
            </ul>
          </div>
        ` : ''}

        ${transcription.sentiment?.overallTone ? `
          <div class="section">
            <h2>Sentiment Analysis</h2>
            <p class="sentiment">${transcription.sentiment.overallTone}</p>
            ${transcription.sentiment.keyEmotionalMoments?.map(moment => `
              <p><strong>${moment.timestamp ? `[${Math.floor(moment.timestamp / 60)}:${(moment.timestamp % 60).toString().padStart(2, '0')}]` : ''}</strong> 
              ${moment.moment} - <em>${moment.emotion}</em></p>
            `).join('') || ''}
          </div>
        ` : ''}
      </body>
    </html>
  `;

  // Create blob and download
  const blob = new Blob([content], { type: 'application/msword' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${transcription.originalFileName || 'transcript'}-analysis.doc`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}