import React, { useState, useEffect, useRef } from 'react';
import { Search, Brain, Trash2, MessageSquare, Bot, Calendar } from 'lucide-react';
import { getAgents, deleteAgent, mergeAgents } from '../../services/agentService';
import { AgentChat } from './AgentChat';
import { AgentSwarmScheduler } from './AgentSwarmScheduler';
import type { Agent } from '../../types';

interface AgentLibraryProps {
  selectedAgents: string[];
  onAgentSelectionChange: (agents: string[]) => void;
}

export function AgentLibrary({ selectedAgents, onAgentSelectionChange }: AgentLibraryProps) {
  const [agents, setAgents] = useState<Agent[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [draggedAgent, setDraggedAgent] = useState<Agent | null>(null);
  const [activeModal, setActiveModal] = useState<{type: 'chat' | 'swarm', agentId: string} | null>(null);

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const data = await getAgents();
      setAgents(data);
    } catch (error) {
      setError('Failed to fetch agents. Please try again.');
      console.error('Error fetching agents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragStart = (agent: Agent, e: React.DragEvent) => {
    setDraggedAgent(agent);
    e.dataTransfer.setData('application/json', JSON.stringify(agent));
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const handleDrop = async (targetAgent: Agent, e: React.DragEvent) => {
    e.preventDefault();
    if (!draggedAgent || draggedAgent.id === targetAgent.id) return;

    try {
      const mergedAgent = await mergeAgents(draggedAgent.id, targetAgent.id);
      setAgents(prev => [...prev.filter(a => 
        a.id !== draggedAgent.id && a.id !== targetAgent.id
      ), mergedAgent]);
      
      // Update selected agents if needed
      if (selectedAgents.includes(draggedAgent.id) || selectedAgents.includes(targetAgent.id)) {
        onAgentSelectionChange(selectedAgents.filter(id => 
          id !== draggedAgent.id && id !== targetAgent.id
        ));
      }
    } catch (error) {
      console.error('Error merging agents:', error);
      alert('Failed to merge agents. Please try again.');
    }
  };

  const handleDeleteAgent = async (agentId: string) => {
    if (!confirm('Are you sure you want to delete this agent?')) return;

    try {
      await deleteAgent(agentId);
      setAgents(prev => prev.filter(agent => agent.id !== agentId));
      if (selectedAgents.includes(agentId)) {
        onAgentSelectionChange(selectedAgents.filter(id => id !== agentId));
      }
    } catch (error) {
      console.error('Error deleting agent:', error);
      alert('Failed to delete agent. Please try again.');
    }
  };

  const filteredAgents = agents.filter(agent => 
    agent.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    agent.expertise.some(exp => exp.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center gap-2">
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      <div className="flex items-center space-x-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search agents..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredAgents.map((agent) => (
          <div
            key={agent.id}
            draggable
            onDragStart={(e) => handleDragStart(agent, e)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(agent, e)}
            className={`bg-white rounded-lg shadow-md p-6 border-2 transition-all duration-200 cursor-move hover:shadow-lg ${
              selectedAgents.includes(agent.id) ? 'border-indigo-600' : 'border-transparent'
            }`}
          >
            <div className="flex items-start justify-between mb-4">
              <div className="flex items-center space-x-3">
                <Brain className="w-6 h-6 text-indigo-600" />
                <h3 className="text-lg font-semibold text-gray-900">{agent.name}</h3>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setActiveModal({ type: 'chat', agentId: agent.id })}
                  className="p-1 text-gray-400 hover:text-indigo-600"
                  title="Chat with agent"
                >
                  <MessageSquare className="w-4 h-4" />
                </button>
                <button
                  onClick={() => setActiveModal({ type: 'swarm', agentId: agent.id })}
                  className="p-1 text-gray-400 hover:text-blue-600"
                  title="Deploy in swarm"
                >
                  <Calendar className="w-4 h-4" />
                </button>
                <button
                  onClick={() => handleDeleteAgent(agent.id)}
                  className="p-1 text-gray-400 hover:text-red-600"
                  title="Delete agent"
                >
                  <Trash2 className="w-4 h-4" />
                </button>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <h4 className="text-sm font-medium text-gray-700">Expertise</h4>
                <div className="flex flex-wrap gap-2 mt-2">
                  {agent.expertise.map((exp, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-indigo-50 text-indigo-700 rounded-full text-sm"
                    >
                      {exp}
                    </span>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-700">Knowledge Base</h4>
                <p className="text-sm text-gray-500 mt-1">
                  {agent.knowledgeBase.length} documents
                </p>
              </div>

              <button
                onClick={() => onAgentSelectionChange(
                  selectedAgents.includes(agent.id)
                    ? selectedAgents.filter(id => id !== agent.id)
                    : [...selectedAgents, agent.id]
                )}
                className={`w-full mt-4 px-4 py-2 rounded-lg transition-colors ${
                  selectedAgents.includes(agent.id)
                    ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                    : 'bg-indigo-600 text-white hover:bg-indigo-700'
                }`}
              >
                {selectedAgents.includes(agent.id) ? 'Selected' : 'Select Agent'}
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Chat Modal */}
      {activeModal?.type === 'chat' && (
        <AgentChat
          agent={agents.find(a => a.id === activeModal.agentId)!}
          onClose={() => setActiveModal(null)}
        />
      )}

      {/* Swarm Modal */}
      {activeModal?.type === 'swarm' && (
        <AgentSwarmScheduler
          agent={agents.find(a => a.id === activeModal.agentId)!}
          onSchedule={(schedule) => {
            // Handle swarm scheduling
            setActiveModal(null);
          }}
          onClose={() => setActiveModal(null)}
        />
      )}
    </div>
  );
}