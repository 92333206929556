import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Upload, 
  Brain, 
  Lock, 
  Mic, 
  FileAudio, 
  Wand, 
  MessageSquare, 
  ArrowRight, 
  CheckCircle,
  Bot,
  Zap
} from 'lucide-react';

export function Home() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center max-w-3xl mx-auto">
          <h1 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-6">
            Transform Speech to Insights with AI
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            Professional transcription services powered by AI, with intelligent analysis and virtual co-workers for enhanced collaboration.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Link
              to="/register"
              className="px-8 py-4 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors flex items-center justify-center"
            >
              <Upload className="w-5 h-5 mr-2" />
              Start Free Trial
            </Link>
            <Link
              to="/demo"
              className="px-8 py-4 bg-white text-indigo-600 border-2 border-indigo-600 rounded-lg hover:bg-indigo-50 transition-colors"
            >
              View Demo
            </Link>
          </div>
        </div>
      </div>

      {/* Main Features */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* File Upload */}
          <div className="bg-white p-8 rounded-xl shadow-lg transform transition-all duration-200 hover:scale-105">
            <div className="flex items-center mb-6">
              <FileAudio className="w-8 h-8 text-indigo-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">Audio Transcription</h2>
            </div>
            <p className="text-gray-600 mb-6">
              Upload pre-recorded audio files and get accurate transcriptions within minutes.
            </p>
            <ul className="space-y-3 mb-6">
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Multiple formats supported
              </li>
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Files up to 500MB
              </li>
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Batch processing
              </li>
            </ul>
          </div>

          {/* AI Analysis */}
          <div className="bg-white p-8 rounded-xl shadow-lg transform transition-all duration-200 hover:scale-105">
            <div className="flex items-center mb-6">
              <Brain className="w-8 h-8 text-indigo-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">AI Analysis</h2>
            </div>
            <p className="text-gray-600 mb-6">
              Get deep insights from your transcriptions with advanced AI analysis.
            </p>
            <ul className="space-y-3 mb-6">
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Topic extraction
              </li>
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Sentiment analysis
              </li>
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Action item detection
              </li>
            </ul>
          </div>

          {/* Virtual Co-worker */}
          <div className="bg-white p-8 rounded-xl shadow-lg transform transition-all duration-200 hover:scale-105">
            <div className="flex items-center mb-6">
              <Bot className="w-8 h-8 text-indigo-600 mr-3" />
              <h2 className="text-2xl font-bold text-gray-900">Virtual Co-worker</h2>
            </div>
            <p className="text-gray-600 mb-6">
              AI-powered assistants that help analyze and understand your content.
            </p>
            <ul className="space-y-3 mb-6">
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Custom knowledge base
              </li>
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Smart suggestions
              </li>
              <li className="flex items-center text-gray-700">
                <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                Contextual insights
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Trust Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">
            Trusted by Teams Worldwide
          </h2>
          <p className="text-xl text-gray-600 mb-12">
            Enterprise-grade security with full GDPR compliance for your peace of mind.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div className="flex items-center justify-center grayscale hover:grayscale-0 transition-all">
              <img src="https://images.unsplash.com/photo-1611162616305-c69b3037f77d?w=200&h=100&fit=crop&crop=entropy&q=80" alt="Company logo" className="h-12" />
            </div>
            <div className="flex items-center justify-center grayscale hover:grayscale-0 transition-all">
              <img src="https://images.unsplash.com/photo-1611162616475-46b635cb6868?w=200&h=100&fit=crop&crop=entropy&q=80" alt="Company logo" className="h-12" />
            </div>
            <div className="flex items-center justify-center grayscale hover:grayscale-0 transition-all">
              <img src="https://images.unsplash.com/photo-1611162617474-5b21e879e113?w=200&h=100&fit=crop&crop=entropy&q=80" alt="Company logo" className="h-12" />
            </div>
            <div className="flex items-center justify-center grayscale hover:grayscale-0 transition-all">
              <img src="https://images.unsplash.com/photo-1611162618071-b39a2ec055fb?w=200&h=100&fit=crop&crop=entropy&q=80" alt="Company logo" className="h-12" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}