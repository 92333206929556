import { openai } from './openai';
import type { TranscriptionAnalysis } from '../../types';

export async function analyzeContent(
  transcript: string,
  language: string
): Promise<TranscriptionAnalysis> {
  try {
    console.log('Starting content analysis...');
    
    const completion = await openai.chat.completions.create({
      model: 'gpt-4',
      messages: [
        {
          role: 'system',
          content: `Analyze this ${language} transcript and provide a comprehensive analysis with the following aspects:
          - Main topics discussed
          - Key phrases including technical terms, project names, dates, action items, and figures
          - Meeting minutes summary
          - Industry context
          - Communication style analysis
          - Key decisions made
          - Risk factors identified
          - Opportunities highlighted
          - Resource requirements
          - Timeline commitments
          - Overall sentiment and emotional progression
          - Areas of consensus and disagreement`
        },
        {
          role: 'user',
          content: transcript
        }
      ],
      temperature: 0.7
    });

    const response = completion.choices[0]?.message?.content;
    if (!response) {
      throw new Error('No analysis response received');
    }

    // Parse the response into structured format
    const analysis = parseAnalysisResponse(response);
    console.log('Analysis completed:', analysis);
    
    return analysis;
  } catch (error) {
    console.error('Analysis error:', error);
    // Return default structure with empty values if analysis fails
    return {
      topics: [],
      keyPhrases: {
        technicalTerms: [],
        projectNames: [],
        dates: [],
        actionItems: [],
        figures: []
      },
      enrichedContent: {
        meetingMinutes: transcript, // Use original transcript as fallback
        industryContext: '',
        communicationStyle: '',
        keyDecisions: [],
        riskFactors: [],
        opportunities: [],
        resourceRequirements: [],
        timelineCommitments: []
      },
      sentiment: {
        overallTone: '',
        emotionalProgression: [],
        keyEmotionalMoments: [],
        consensusAreas: [],
        disagreementAreas: []
      }
    };
  }
}

function parseAnalysisResponse(response: string): TranscriptionAnalysis {
  try {
    // First attempt to parse as JSON in case the model returns JSON
    try {
      const jsonResponse = JSON.parse(response);
      if (isValidAnalysis(jsonResponse)) {
        return jsonResponse;
      }
    } catch (e) {
      // Continue with text parsing if JSON parsing fails
    }

    // Parse the text response into structured format
    const topics = extractList(response, 'topics', 'key phrases');
    const keyPhrases = {
      technicalTerms: extractList(response, 'technical terms', 'project names'),
      projectNames: extractList(response, 'project names', 'dates'),
      dates: extractList(response, 'dates', 'action items'),
      actionItems: extractList(response, 'action items', 'figures'),
      figures: extractList(response, 'figures', 'meeting minutes')
    };

    const enrichedContent = {
      meetingMinutes: extractSection(response, 'meeting minutes', 'industry context'),
      industryContext: extractSection(response, 'industry context', 'communication style'),
      communicationStyle: extractSection(response, 'communication style', 'key decisions'),
      keyDecisions: extractList(response, 'key decisions', 'risk factors'),
      riskFactors: extractList(response, 'risk factors', 'opportunities'),
      opportunities: extractList(response, 'opportunities', 'resource requirements'),
      resourceRequirements: extractList(response, 'resource requirements', 'timeline'),
      timelineCommitments: extractList(response, 'timeline commitments', 'sentiment')
    };

    const sentiment = {
      overallTone: extractSection(response, 'overall sentiment', 'emotional progression'),
      emotionalProgression: extractList(response, 'emotional progression', 'key emotional moments'),
      keyEmotionalMoments: extractEmotionalMoments(response),
      consensusAreas: extractList(response, 'consensus areas', 'disagreement'),
      disagreementAreas: extractList(response, 'disagreement areas', 'end')
    };

    return {
      topics,
      keyPhrases,
      enrichedContent,
      sentiment
    };
  } catch (error) {
    console.error('Error parsing analysis response:', error);
    throw error;
  }
}

function isValidAnalysis(obj: any): obj is TranscriptionAnalysis {
  return (
    obj &&
    Array.isArray(obj.topics) &&
    typeof obj.keyPhrases === 'object' &&
    typeof obj.enrichedContent === 'object' &&
    typeof obj.sentiment === 'object'
  );
}

function extractList(text: string, startMarker: string, endMarker: string): string[] {
  try {
    const regex = new RegExp(`${startMarker}[:\\s]+(.*?)(?=${endMarker}|$)`, 'is');
    const match = text.match(regex);
    if (!match) return [];

    return match[1]
      .split(/[\n,]/)
      .map(item => item.trim())
      .filter(item => item && !item.includes(':') && item.length > 1);
  } catch (e) {
    return [];
  }
}

function extractSection(text: string, startMarker: string, endMarker: string): string {
  try {
    const regex = new RegExp(`${startMarker}[:\\s]+(.*?)(?=${endMarker}|$)`, 'is');
    const match = text.match(regex);
    return match ? match[1].trim() : '';
  } catch (e) {
    return '';
  }
}

function extractEmotionalMoments(text: string): Array<{ moment: string; emotion: string; timestamp?: number }> {
  try {
    const section = extractSection(text, 'key emotional moments', 'consensus areas');
    if (!section) return [];

    return section
      .split('\n')
      .map(line => {
        const match = line.match(/(?:\[([\d:]+)\])?\s*(.+?):\s*(.+)/);
        if (!match) return null;

        const [, timestamp, moment, emotion] = match;
        return {
          moment: moment?.trim() || '',
          emotion: emotion?.trim() || '',
          ...(timestamp && { timestamp: parseTimestamp(timestamp) })
        };
      })
      .filter((item): item is { moment: string; emotion: string; timestamp?: number } => !!item);
  } catch (e) {
    return [];
  }
}

function parseTimestamp(timestamp: string): number | undefined {
  try {
    const [minutes, seconds] = timestamp.split(':').map(Number);
    return minutes * 60 + seconds;
  } catch (e) {
    return undefined;
  }
}