import React from 'react';
import { Check, Clock } from 'lucide-react';

interface ContentEnrichmentProps {
  enrichedContent: {
    meetingMinutes?: string;
    industryContext?: string;
    communicationStyle?: string;
    keyDecisions?: string[];
    riskFactors?: string[];
    opportunities?: string[];
    resourceRequirements?: string[];
    timelineCommitments?: string[];
  };
}

export function ContentEnrichment({ enrichedContent }: ContentEnrichmentProps) {
  return (
    <div className="space-y-6">
      {enrichedContent.meetingMinutes && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Meeting Minutes</h3>
          <p className="text-gray-700 whitespace-pre-wrap">{enrichedContent.meetingMinutes}</p>
        </div>
      )}

      {enrichedContent.industryContext && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Industry Context</h3>
          <p className="text-gray-700">{enrichedContent.industryContext}</p>
        </div>
      )}

      {enrichedContent.communicationStyle && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Communication Style</h3>
          <p className="text-gray-700">{enrichedContent.communicationStyle}</p>
        </div>
      )}

      {enrichedContent.keyDecisions && enrichedContent.keyDecisions.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Key Decisions</h3>
          <ul className="space-y-2">
            {enrichedContent.keyDecisions.map((decision, index) => (
              <li key={index} className="flex items-start space-x-2">
                <Check className="w-5 h-5 text-green-500 mt-0.5 flex-shrink-0" />
                <span className="text-gray-700">{decision}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {enrichedContent.riskFactors && enrichedContent.riskFactors.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Risk Factors</h3>
          <ul className="space-y-2">
            {enrichedContent.riskFactors.map((risk, index) => (
              <li key={index} className="flex items-start space-x-2">
                <span className="text-red-500">•</span>
                <span className="text-gray-700">{risk}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {enrichedContent.opportunities && enrichedContent.opportunities.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Opportunities</h3>
          <ul className="space-y-2">
            {enrichedContent.opportunities.map((opportunity, index) => (
              <li key={index} className="flex items-start space-x-2">
                <span className="text-green-500">•</span>
                <span className="text-gray-700">{opportunity}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {enrichedContent.resourceRequirements && enrichedContent.resourceRequirements.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Resource Requirements</h3>
          <ul className="space-y-2">
            {enrichedContent.resourceRequirements.map((resource, index) => (
              <li key={index} className="flex items-start space-x-2">
                <span className="text-blue-500">•</span>
                <span className="text-gray-700">{resource}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {enrichedContent.timelineCommitments && enrichedContent.timelineCommitments.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Timeline Commitments</h3>
          <ul className="space-y-2">
            {enrichedContent.timelineCommitments.map((timeline, index) => (
              <li key={index} className="flex items-start space-x-2">
                <Clock className="w-5 h-5 text-indigo-500 mt-0.5 flex-shrink-0" />
                <span className="text-gray-700">{timeline}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}