import React, { useState, useRef, useEffect } from 'react';
import { Mic, Square, AlertCircle, Play, Pause, Download, Brain } from 'lucide-react';
import { uploadAudioForTranscription } from '../../services/transcriptionService';
import type { Transcription } from '../../types';

interface MeetingRecorderProps {
  maxDuration: number;
  onTranscriptionComplete: (transcription: Transcription) => void;
}

export function MeetingRecorder({ maxDuration, onTranscriptionComplete }: MeetingRecorderProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState<Blob | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioPlayer = useRef<HTMLAudioElement | null>(null);
  const chunks = useRef<Blob[]>([]);
  const timerRef = useRef<number>();

  useEffect(() => {
    const now = new Date();
    setStartDate(now.toISOString().split('T')[0]);
    setStartTime(now.toTimeString().split(' ')[0].slice(0, 5));

    return () => {
      if (timerRef.current) {
        window.clearInterval(timerRef.current);
      }
      if (mediaRecorder.current?.state === 'recording') {
        mediaRecorder.current.stop();
      }
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, []);

  const startRecording = async () => {
    try {
      setError(null);
      chunks.current = [];
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream, {
        mimeType: 'audio/webm'
      });

      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(chunks.current, { type: 'audio/webm' });
        const url = URL.createObjectURL(blob);
        setRecordedAudio(blob);
        setAudioUrl(url);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
      setDuration(0);

      timerRef.current = window.setInterval(() => {
        setDuration(prev => {
          if (prev >= maxDuration) {
            stopRecording();
            return prev;
          }
          return prev + 1;
        });
      }, 1000);

    } catch (error) {
      setError('Failed to start recording');
      console.error('Recording error:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current?.state === 'recording') {
      mediaRecorder.current.stop();
      mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
    }
    if (timerRef.current) {
      window.clearInterval(timerRef.current);
    }
    setIsRecording(false);
  };

  const togglePlayback = () => {
    if (!audioPlayer.current) return;
    
    if (isPlaying) {
      audioPlayer.current.pause();
    } else {
      audioPlayer.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleDownload = () => {
    if (!recordedAudio || !audioUrl) return;
    const link = document.createElement('a');
    link.href = audioUrl;
    link.download = `meeting-${startDate}-${startTime.replace(':', '-')}.webm`;
    link.click();
  };

  const handleAnalyze = async () => {
    if (!recordedAudio) return;
    
    setIsProcessing(true);
    setError(null);

    try {
      const file = new File([recordedAudio], `meeting-${startDate}-${startTime}.webm`, { 
        type: 'audio/webm'
      });
      
      const transcription = await uploadAudioForTranscription({
        file,
        onProgress: (progress) => {
          console.log('Upload progress:', progress);
        }
      });

      onTranscriptionComplete({
        ...transcription,
        originalFile: file,
        originalFileName: file.name,
        duration
      });

      // Clear recording after successful analysis
      setRecordedAudio(null);
      setAudioUrl(null);
      setDuration(0);
    } catch (error) {
      console.error('Analysis error:', error);
      setError('Failed to analyze recording');
    } finally {
      setIsProcessing(false);
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="text-center mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Record Meeting</h2>
        <p className="text-gray-600 mt-2">Maximum duration: {formatTime(maxDuration)}</p>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            disabled={isRecording}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Start Time</label>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            disabled={isRecording}
          />
        </div>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          <span>{error}</span>
        </div>
      )}

      <div className="flex flex-col items-center space-y-4">
        <div className="text-4xl font-bold text-gray-900">
          {formatTime(duration)}
        </div>

        {audioUrl && (
          <audio 
            ref={audioPlayer} 
            src={audioUrl} 
            onEnded={() => setIsPlaying(false)} 
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
          />
        )}

        {!recordedAudio ? (
          <button
            onClick={isRecording ? stopRecording : startRecording}
            disabled={isProcessing}
            className={`flex items-center px-6 py-3 rounded-full ${
              isProcessing
                ? 'bg-gray-400 cursor-not-allowed'
                : isRecording
                ? 'bg-red-600 hover:bg-red-700'
                : 'bg-indigo-600 hover:bg-indigo-700'
            } text-white transition-colors`}
          >
            {isProcessing ? (
              'Processing...'
            ) : isRecording ? (
              <>
                <Square className="w-5 h-5 mr-2" />
                Stop Recording
              </>
            ) : (
              <>
                <Mic className="w-5 h-5 mr-2" />
                Start Recording
              </>
            )}
          </button>
        ) : (
          <div className="flex space-x-4">
            <button
              onClick={togglePlayback}
              className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              {isPlaying ? (
                <>
                  <Pause className="w-5 h-5 mr-2" />
                  Pause
                </>
              ) : (
                <>
                  <Play className="w-5 h-5 mr-2" />
                  Play
                </>
              )}
            </button>
            <button
              onClick={handleDownload}
              className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
            >
              <Download className="w-5 h-5 mr-2" />
              Download
            </button>
            <button
              onClick={handleAnalyze}
              disabled={isProcessing}
              className={`flex items-center px-4 py-2 ${
                isProcessing 
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-purple-600 hover:bg-purple-700'
              } text-white rounded-lg`}
            >
              <Brain className="w-5 h-5 mr-2" />
              {isProcessing ? 'Analyzing...' : 'Analyze Meeting'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}