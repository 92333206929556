import React from 'react';
import { FileAudio, Clock, Check, AlertCircle } from 'lucide-react';

interface TranscriptionProgressProps {
  fileName: string;
  progress: number;
  stage: string;
  status: 'uploading' | 'processing' | 'completed' | 'failed';
  error?: string;
}

export function TranscriptionProgress({
  fileName,
  progress,
  stage,
  status,
  error
}: TranscriptionProgressProps) {
  return (
    <div className="bg-white rounded-lg shadow p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-3">
          <FileAudio className="w-5 h-5 text-indigo-600" />
          <div>
            <h3 className="font-medium text-gray-900">{fileName}</h3>
            <p className="text-sm text-gray-500">{stage}</p>
          </div>
        </div>
        {status === 'uploading' && <Clock className="w-5 h-5 text-blue-500 animate-spin" />}
        {status === 'processing' && <Clock className="w-5 h-5 text-indigo-500 animate-spin" />}
        {status === 'completed' && <Check className="w-5 h-5 text-green-500" />}
        {status === 'failed' && <AlertCircle className="w-5 h-5 text-red-500" />}
      </div>

      <div className="w-full bg-gray-200 rounded-full h-2">
        <div
          className={`h-2 rounded-full transition-all duration-300 ${
            status === 'failed'
              ? 'bg-red-500'
              : status === 'completed'
              ? 'bg-green-500'
              : 'bg-indigo-600'
          }`}
          style={{ width: `${progress}%` }}
        />
      </div>

      <div className="flex justify-between mt-2">
        <span className="text-sm text-gray-500">
          {status === 'completed'
            ? 'Transcription completed'
            : status === 'failed'
            ? error || 'Transcription failed'
            : `${progress}% complete`}
        </span>
      </div>
    </div>
  );
}