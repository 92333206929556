import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { User } from '../types';

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  token: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, password: string, name: string) => Promise<void>;
  updateUser: (user: Partial<User>) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      token: null,
      login: async (email: string, password: string) => {
        try {
          // Simulated API call for demo
          if (email === 'test@example.com' && password === 'test123') {
            const user: User = {
              id: '1',
              email,
              name: 'Test User',
              credits: 100,
              avatar: 'https://api.dicebear.com/7.x/avataaars/svg?seed=test',
              plan: 'pro',
              transcriptionsUsed: 45,
              transcriptionsLimit: 100,
              hoursUsed: 4.5,
              hoursLimit: 12,
              billingCycle: 'Monthly (Renews Mar 20, 2024)'
            };
            set({ 
              user, 
              isAuthenticated: true,
              token: 'demo-token'
            });
          } else {
            throw new Error('Invalid credentials');
          }
        } catch (error) {
          throw error instanceof Error ? error : new Error('Login failed');
        }
      },
      logout: () => {
        set({ 
          user: null, 
          isAuthenticated: false,
          token: null
        });
      },
      register: async (email: string, password: string, name: string) => {
        try {
          const user: User = {
            id: Math.random().toString(36).substr(2, 9),
            email,
            name,
            credits: 50,
            avatar: `https://api.dicebear.com/7.x/avataaars/svg?seed=${email}`,
            plan: 'free',
            transcriptionsUsed: 0,
            transcriptionsLimit: 2,
            hoursUsed: 0,
            hoursLimit: 2,
            billingCycle: 'Free Plan'
          };
          set({ 
            user, 
            isAuthenticated: true,
            token: 'demo-token'
          });
        } catch (error) {
          throw error instanceof Error ? error : new Error('Registration failed');
        }
      },
      updateUser: (userData: Partial<User>) => {
        set((state) => ({
          user: state.user ? { ...state.user, ...userData } : null
        }));
      },
    }),
    {
      name: 'audio-transcribe-auth',
      partialize: (state) => ({
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        token: state.token
      }),
    }
  )
);