import React from 'react';
import { Mail } from 'lucide-react';

export function Contact() {
  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">Contact Us</h1>
        <p className="text-xl text-gray-600 mb-8">
          CyberResilience Pro Media Publications
        </p>
        <div className="inline-flex items-center space-x-2 text-indigo-600">
          <Mail className="w-5 h-5" />
          <a href="mailto:igor.van.gemert@cyberresilience.pro">
            igor.van.gemert@cyberresilience.pro
          </a>
        </div>
      </div>
    </div>
  );
}