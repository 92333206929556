import OpenAI from 'openai';
import { Agent, Message } from '../types';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function* streamAgentResponse(
  agent: Agent,
  userMessage: string,
  context: Message[]
): AsyncGenerator<string> {
  try {
    // Filter out thinking messages from context
    const filteredContext = context.filter(msg => 
      !msg.content.includes(agent.persona.response_patterns.thinking)
    );

    const messages = [
      { role: 'system', content: agent.systemPrompt },
      ...filteredContext.map(msg => ({
        role: msg.role === 'user' ? 'user' : 'assistant',
        content: msg.content
      })),
      { role: 'user', content: userMessage }
    ];

    const stream = await openai.chat.completions.create({
      model: 'gpt-4-turbo-preview',
      messages,
      temperature: agent.temperature,
      max_tokens: agent.maxTokens,
      stream: true
    });

    for await (const chunk of stream) {
      if (chunk.choices[0]?.delta?.content) {
        yield chunk.choices[0].delta.content;
      }
    }
  } catch (error) {
    console.error('Error streaming agent response:', error);
    throw error;
  }
}