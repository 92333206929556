import React from 'react';
import { Link } from 'react-router-dom';
import { FileAudio, User, Brain, LayoutDashboard, ChevronDown } from 'lucide-react';
import { useAuthStore } from '../../store/authStore';

export function Navbar() {
  const { isAuthenticated, user, logout } = useAuthStore();

  return (
    <header className="bg-white shadow-sm sticky top-0 z-50">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
        <Link to="/" className="flex items-center space-x-2">
          <FileAudio className="w-8 h-8 text-indigo-600" />
          <span className="text-xl font-bold text-gray-900 hidden sm:inline">AudioScribe</span>
        </Link>
        
        <div className="flex items-center space-x-4 sm:space-x-8">
          {isAuthenticated ? (
            <>
              <Link to="/dashboard" className="flex items-center space-x-1 text-gray-600 hover:text-gray-900">
                <LayoutDashboard className="w-4 h-4" />
                <span className="hidden sm:inline">Dashboard</span>
              </Link>
              <Link to="/transcribe" className="flex items-center space-x-1 text-gray-600 hover:text-gray-900">
                <FileAudio className="w-4 h-4" />
                <span className="hidden sm:inline">Transcribe</span>
              </Link>
              <Link to="/virtual-coworker" className="flex items-center space-x-1 text-gray-600 hover:text-gray-900">
                <Brain className="w-4 h-4" />
                <span className="hidden sm:inline">AI Assistant</span>
              </Link>
              <div className="relative group">
                <button className="flex items-center space-x-2 focus:outline-none">
                  <img
                    src={user?.avatar}
                    alt={user?.name}
                    className="w-8 h-8 rounded-full"
                  />
                  <ChevronDown className="w-4 h-4 text-gray-500" />
                </button>
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 hidden group-hover:block">
                  <div className="px-4 py-2 border-b">
                    <p className="text-sm font-medium text-gray-900">{user?.name}</p>
                    <p className="text-xs text-gray-500">{user?.email}</p>
                  </div>
                  <Link
                    to="/account"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Account & Subscription
                  </Link>
                  <button
                    onClick={logout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="text-gray-600 hover:text-gray-900 hidden sm:block"
              >
                Sign in
              </Link>
              <Link
                to="/register"
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
              >
                Start Free Trial
              </Link>
            </>
          )}
        </div>
      </nav>
    </header>
  );
}