import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Transcription } from '../types';

export async function generatePDF(transcription: Transcription): Promise<void> {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const margin = 20;

  // Header
  doc.setFontSize(24);
  doc.text(transcription.originalFileName || 'Transcription', pageWidth / 2, 20, { align: 'center' });

  // Metadata
  doc.setFontSize(12);
  doc.text(`Date: ${new Date(transcription.createdAt).toLocaleString()}`, margin, 40);
  doc.text(`Duration: ${Math.round(transcription.duration / 60)} minutes`, margin, 50);
  doc.text(`Language: ${transcription.languageInfo?.name || transcription.language}`, margin, 60);
  doc.text(`Words: ${transcription.wordCount?.toLocaleString() || 0}`, margin, 70);

  // Topics
  if (transcription.topics && transcription.topics.length > 0) {
    doc.setFontSize(14);
    doc.text('Topics:', margin, 90);
    doc.setFontSize(12);
    transcription.topics.forEach((topic, index) => {
      doc.text(`• ${topic}`, margin + 10, 100 + (index * 10));
    });
  }

  let yPosition = 120 + ((transcription.topics?.length || 0) * 10);

  // Main transcript
  if (transcription.transcript) {
    doc.setFontSize(14);
    doc.text('Transcript:', margin, yPosition);
    yPosition += 10;
    doc.setFontSize(12);

    const lines = doc.splitTextToSize(transcription.transcript, pageWidth - (margin * 2));
    lines.forEach(line => {
      if (yPosition > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPosition = 20;
      }
      doc.text(line, margin, yPosition);
      yPosition += 10;
    });
  }

  // Key insights
  if (transcription.enrichedContent?.keyDecisions?.length) {
    doc.addPage();
    yPosition = 20;
    doc.setFontSize(14);
    doc.text('Key Decisions:', margin, yPosition);
    yPosition += 10;
    doc.setFontSize(12);
    transcription.enrichedContent.keyDecisions.forEach(decision => {
      if (yPosition > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPosition = 20;
      }
      doc.text(`• ${decision}`, margin, yPosition);
      yPosition += 10;
    });
  }

  // Action items
  if (transcription.keyPhrases?.actionItems?.length) {
    if (yPosition > doc.internal.pageSize.height - 60) {
      doc.addPage();
      yPosition = 20;
    } else {
      yPosition += 20;
    }
    doc.setFontSize(14);
    doc.text('Action Items:', margin, yPosition);
    yPosition += 10;
    doc.setFontSize(12);
    transcription.keyPhrases.actionItems.forEach(item => {
      if (yPosition > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPosition = 20;
      }
      doc.text(`• ${item}`, margin, yPosition);
      yPosition += 10;
    });
  }

  // Footer
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `Page ${i} of ${pageCount}`,
      pageWidth / 2,
      doc.internal.pageSize.height - 10,
      { align: 'center' }
    );
  }

  // Download
  doc.save(`${transcription.originalFileName || 'transcript'}-${new Date().toISOString().split('T')[0]}.pdf`);
}