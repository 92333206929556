import React, { useState, useEffect, useRef } from 'react';
import { Brain, Download, MessageSquare, AlertCircle, Volume2, Ear, Clock, Square } from 'lucide-react';
import { RealtimeAudioService } from '../../services/realtimeAudioService';
import { analyzeContent } from '../../services/transcription/analysis';
import { generatePDF } from '../../utils/pdfGenerator';
import { exportToWord } from '../../utils/wordExporter';
import type { Transcription } from '../../types';

interface LiveMeetingAssistantProps {
  onTranscriptionComplete: (transcription: Transcription) => void;
  isOtherSessionActive: boolean;
  onSessionChange: (isActive: boolean) => void;
}

export function LiveMeetingAssistant({ 
  onTranscriptionComplete, 
  isOtherSessionActive,
  onSessionChange 
}: LiveMeetingAssistantProps) {
  const [transcript, setTranscript] = useState('');
  const [topics, setTopics] = useState<string[]>([]);
  const [questions, setQuestions] = useState<string[]>([]);
  const [isActive, setIsActive] = useState(false);
  const [startTime, setStartTime] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endTime, setEndTime] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [volume, setVolume] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const audioServiceRef = useRef<RealtimeAudioService | null>(null);
  const volumeAnalyzerRef = useRef<AnalyserNode | null>(null);
  const animationFrameRef = useRef<number>();
  const timerRef = useRef<number>();
  const lastTranscriptRef = useRef<string>('');
  const pauseTimeoutRef = useRef<NodeJS.Timeout>();
  const audioContextRef = useRef<AudioContext | null>(null);
  const streamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    const now = new Date();
    setStartDate(now.toISOString().split('T')[0]);
    setStartTime(now.toTimeString().split(' ')[0].slice(0, 5));

    return () => {
      cleanupResources();
    };
  }, []);

  const cleanupResources = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = undefined;
    }

    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }

    if (pauseTimeoutRef.current) {
      clearTimeout(pauseTimeoutRef.current);
      pauseTimeoutRef.current = undefined;
    }

    if (audioServiceRef.current) {
      audioServiceRef.current.stopListening();
      audioServiceRef.current = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }

    if (audioContextRef.current?.state !== 'closed') {
      audioContextRef.current?.close();
      audioContextRef.current = null;
    }

    volumeAnalyzerRef.current = null;
  };

  const updateVolume = () => {
    if (!volumeAnalyzerRef.current || !isActive) return;

    const dataArray = new Uint8Array(volumeAnalyzerRef.current.frequencyBinCount);
    volumeAnalyzerRef.current.getByteFrequencyData(dataArray);
    const average = dataArray.reduce((a, b) => a + b) / dataArray.length;
    const normalizedVolume = Math.min(Math.round((average / 255) * 10), 10);
    setVolume(normalizedVolume);

    setIsListening(normalizedVolume > 2);

    if (normalizedVolume <= 2) {
      if (!pauseTimeoutRef.current) {
        pauseTimeoutRef.current = setTimeout(() => {
          if (lastTranscriptRef.current !== transcript) {
            setIsTranscribing(true);
            lastTranscriptRef.current = transcript;
          }
        }, 1500);
      }
    } else {
      if (pauseTimeoutRef.current) {
        clearTimeout(pauseTimeoutRef.current);
        pauseTimeoutRef.current = undefined;
      }
    }

    animationFrameRef.current = requestAnimationFrame(updateVolume);
  };

  const handleStart = async () => {
    if (isOtherSessionActive) {
      setError('Another recording session is active');
      return;
    }

    try {
      setError(null);
      cleanupResources();

      audioContextRef.current = new AudioContext();
      const analyzer = audioContextRef.current.createAnalyser();
      analyzer.fftSize = 256;
      volumeAnalyzerRef.current = analyzer;

      streamRef.current = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });

      const source = audioContextRef.current.createMediaStreamSource(streamRef.current);
      source.connect(analyzer);

      audioServiceRef.current = new RealtimeAudioService(
        (text) => {
          setTranscript(prev => prev + ' ' + text);
          setIsTranscribing(false);
          
          // Analyze content after each transcription
          analyzeContent(text, 'en').then(analysis => {
            if (analysis.topics) {
              setTopics(prev => [...new Set([...prev, ...analysis.topics])]);
            }
            if (analysis.enrichedContent?.keyDecisions) {
              setQuestions(prev => [...new Set([...prev, ...analysis.enrichedContent.keyDecisions])]);
            }
          }).catch(console.error);
        },
        (error) => {
          setError(error);
          handleStop();
        },
        (isListening) => setIsListening(isListening)
      );

      await audioServiceRef.current.startListening();
      updateVolume();

      timerRef.current = window.setInterval(() => {
        setElapsedTime(prev => prev + 1);
      }, 1000);

      setIsActive(true);
      onSessionChange(true);

    } catch (error) {
      console.error('Start error:', error);
      setError('Failed to start meeting assistant');
      cleanupResources();
      setIsActive(false);
      onSessionChange(false);
    }
  };

  const handleStop = async () => {
    try {
      const now = new Date();
      setEndDate(now.toISOString().split('T')[0]);
      setEndTime(now.toTimeString().split(' ')[0].slice(0, 5));

      cleanupResources();
      setIsActive(false);
      onSessionChange(false);

      if (transcript) {
        const transcription: Transcription = {
          id: crypto.randomUUID(),
          status: 'completed',
          transcript,
          duration: elapsedTime,
          createdAt: new Date(startDate + 'T' + startTime).toISOString(),
          completedAt: now.toISOString(),
          language: 'en',
          model: 'whisper',
          progress: 100,
          topics,
          enrichedContent: {
            keyDecisions: questions
          }
        };
        onTranscriptionComplete(transcription);
      }
    } catch (error) {
      console.error('Stop error:', error);
      setError('Failed to stop meeting assistant');
      cleanupResources();
      setIsActive(false);
      onSessionChange(false);
    }
  };

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleExport = async (format: 'pdf' | 'word') => {
    if (!transcript) return;

    const data = {
      transcript,
      topics,
      questions,
      startTime: `${startDate} ${startTime}`,
      endTime: endTime ? `${endDate} ${endTime}` : undefined,
      duration: elapsedTime
    };

    if (format === 'pdf') {
      await generatePDF(data as any);
    } else {
      await exportToWord(data as any, 'User');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Live Meeting Assistant</h2>
          <p className="text-sm text-gray-500">Real-time transcription and analysis</p>
        </div>
        
        {isActive && (
          <div className="flex items-center space-x-4">
            <div className="text-sm text-gray-500">
              <Clock className="w-4 h-4 inline mr-1" />
              {formatTime(elapsedTime)}
            </div>
            <div className="flex items-center space-x-1">
              {isListening && <Ear className="w-5 h-5 text-green-500 animate-pulse" />}
              {isTranscribing && <Brain className="w-5 h-5 text-indigo-500 animate-spin" />}
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            disabled={isActive}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Start Time</label>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="w-full px-3 py-2 border rounded-md"
            disabled={isActive}
          />
        </div>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          <span>{error}</span>
        </div>
      )}

      <div className="space-y-6">
        {isActive && (
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm text-gray-500">
              <span>Volume Level</span>
              <span>{volume}/10</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
                style={{ width: `${(volume / 10) * 100}%` }}
              />
            </div>
          </div>
        )}

        <div className="space-y-4">
          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Live Transcript</h3>
            <div className="h-48 overflow-y-auto whitespace-pre-wrap text-gray-600">
              {transcript || 'Transcript will appear here...'}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-50 rounded-lg p-4">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Topics</h3>
              <div className="space-y-1">
                {topics.length > 0 ? (
                  topics.map((topic, index) => (
                    <div key={index} className="text-sm text-gray-600">• {topic}</div>
                  ))
                ) : (
                  <div className="text-sm text-gray-500 italic">
                    {isActive ? 'Listening and analyzing...' : 'No topics detected yet'}
                  </div>
                )}
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-4">
              <h3 className="text-sm font-medium text-gray-700 mb-2">Questions</h3>
              <div className="space-y-1">
                {questions.length > 0 ? (
                  questions.map((question, index) => (
                    <div key={index} className="text-sm text-gray-600">• {question}</div>
                  ))
                ) : (
                  <div className="text-sm text-gray-500 italic">
                    {isActive ? 'Listening and analyzing...' : 'No questions generated yet'}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <button
            onClick={isActive ? handleStop : handleStart}
            disabled={isOtherSessionActive && !isActive}
            className={`px-4 py-2 rounded-lg flex items-center ${
              isActive
                ? 'bg-red-600 hover:bg-red-700 text-white'
                : isOtherSessionActive
                ? 'bg-gray-300 cursor-not-allowed text-gray-500'
                : 'bg-indigo-600 hover:bg-indigo-700 text-white'
            }`}
          >
            {isActive ? (
              <>
                <Square className="w-5 h-5 mr-2" />
                End Session
              </>
            ) : (
              <>
                <Brain className="w-5 h-5 mr-2" />
                Start Session
              </>
            )}
          </button>

          {transcript && !isActive && (
            <div className="flex space-x-2">
              <button
                onClick={() => handleExport('pdf')}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 flex items-center"
              >
                <Download className="w-5 h-5 mr-2" />
                PDF
              </button>
              <button
                onClick={() => handleExport('word')}
                className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 flex items-center"
              >
                <Download className="w-5 h-5 mr-2" />
                Word
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}