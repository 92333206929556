import React from 'react';

export function Terms() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-4">Terms of Use</h1>
      <p className="text-sm text-gray-600 mb-8">Effective Date: 28/08/2024</p>

      <div className="prose prose-lg max-w-none space-y-8">
        <section>
          <h2 className="text-2xl font-bold text-gray-900">Introduction</h2>
          <p>Welcome to Audioscribe.today, a transcription and automated knowledge service offered by CyberResilience.Pro, AI Media Concept Publishers Division, based in the Netherlands (collectively referred to as "We," "Us," or "Our"). By accessing, browsing, or otherwise using this website and associated services (collectively, the "Services"), You, the user ("User," "You," or "Your"), consent to be legally bound by these Terms of Use (the "Terms") and Our Privacy Policy. If You do not agree with these Terms, please cease use of the Services immediately.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">1. Acceptance of Terms and Conditions</h2>
          <p>By using the Services, You affirm that You are of legal age and capacity to enter into this agreement. Your continued use of the Services following any amendments to these Terms signifies Your acceptance of such changes. These Terms apply to all Users, whether registered or not.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">2. Description of Services</h2>
          <p>Audioscribe.today offers AI-based transcription and automated knowledge services on an "as-is" and "as-available" basis. Given the nature of AI and automated knowledge systems, We make no representations, guarantees, or warranties regarding the accuracy, completeness, or suitability of any information provided. The Company retains the right, at its sole discretion, to amend, modify, suspend, or terminate any or all Services without notice or liability.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">3. Assumption of Risk in AI Use</h2>
          <p>By accessing and using these AI-powered Services, You acknowledge that automated systems and AI-driven knowledge engines may yield errors, omissions, or results inconsistent with traditional professional standards. You expressly assume all risks associated with the use of AI technology and automated systems, including but not limited to the risks of inaccuracy, unpredictability, and data loss. The Company assumes no liability for decisions or actions taken based on information provided by the Services.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">4. No Warranty Disclaimer</h2>
          <p>To the fullest extent permissible under Dutch and EU law, We disclaim all warranties related to the Services, whether express, implied, statutory, or otherwise, including but not limited to implied warranties of merchantability, fitness for a particular purpose, title, accuracy, and non-infringement.</p>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>No Guarantee of Accuracy or Suitability:</strong> The Company makes no warranty that the content generated by AI-based Services will meet any specific standards of accuracy, quality, or reliability.</li>
            <li><strong>Reliance on AI Content:</strong> Any reliance on content or advice generated through Our AI systems is at Your sole discretion and risk.</li>
            <li><strong>No Guarantee of Availability:</strong> We do not guarantee uninterrupted access to the Services or that the Site will be free from viruses, malware, or other harmful components.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">5. Limitation of Liability</h2>
          <p>To the maximum extent permitted by law, the Company shall not be liable for any direct, indirect, incidental, consequential, special, or punitive damages arising out of or related to:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Your use of or inability to use the Services</li>
            <li>Errors, inaccuracies, or omissions in AI-generated content</li>
            <li>Any temporary or permanent interruption of the Services</li>
            <li>Unauthorized access to or alteration of Your transmissions or data</li>
            <li>Statements, actions, or omissions of any third party</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">6. Waiver of Claims Related to AI Use</h2>
          <p>By using Our AI-based Services, You waive any claims or legal action against the Company, its affiliates, or third-party providers based on the results, performance, or consequences of any AI-generated content.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">7. Indemnification</h2>
          <p>You agree to indemnify, defend, and hold harmless the Company from any claims arising from:</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>Your use of the Services</li>
            <li>Your violation of these Terms</li>
            <li>Third-party claims from Your use of the Services</li>
            <li>Violations of applicable laws</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">8. Data Privacy and Security</h2>
          <p>The use of Services is governed by Our Privacy Policy. Your data is processed in accordance with Dutch and European data protection regulations, including GDPR. The Company hosts its servers in the EU-West Region.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">9. Intellectual Property</h2>
          <p>All content, trademarks, service marks, software, designs, and other intellectual property rights contained within the Services are owned or licensed by the Company.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">10. Third-Party Content and Links</h2>
          <p>The Services may include content, links, or references to third-party websites or services. We do not endorse or assume responsibility for third-party content, policies, practices, or services.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">11. Modifications to Terms</h2>
          <p>The Company reserves the right to modify these Terms at any time. Changes are effective immediately upon posting.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">12. Severability</h2>
          <p>If any provision of these Terms is found to be invalid or unenforceable, that provision shall be removed while the remaining provisions shall remain enforceable.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">13. Governing Law and Jurisdiction</h2>
          <p>These Terms shall be governed by the laws of the Netherlands, subject to EU regulations. Any legal proceedings shall be subject to Dutch courts' jurisdiction.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">14. Entire Agreement</h2>
          <p>These Terms and Privacy Policy represent the entire agreement between You and the Company regarding Your use of the Services.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">15. Contact Information</h2>
          <p>For any inquiries regarding these Terms, please contact us at:</p>
          <p className="font-medium mt-2">Email: terms@audioscribe.today</p>
          <p className="font-medium">Mailing Address: CyberResilience.Pro, AI Media Concept Publishers Division</p>
        </section>
      </div>
    </div>
  );
}