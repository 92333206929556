import React from 'react';
import { FileAudio, Clock } from 'lucide-react';
import { useUsageStore } from '../../store/usageStore';

export function UsageStats() {
  const { 
    transcriptionsUsed, 
    transcriptionsLimit, 
    hoursUsed, 
    hoursLimit,
    formatHoursUsed 
  } = useUsageStore();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <FileAudio className="w-5 h-5 text-indigo-600" />
            <h3 className="font-medium text-gray-900">Transcriptions</h3>
          </div>
          <span className="text-sm text-gray-500">
            {transcriptionsUsed} / {transcriptionsLimit}
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
            style={{ width: `${(transcriptionsUsed / transcriptionsLimit) * 100}%` }}
          />
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <Clock className="w-5 h-5 text-indigo-600" />
            <h3 className="font-medium text-gray-900">Hours Transcribed</h3>
          </div>
          <span className="text-sm text-gray-500">
            {formatHoursUsed()} / {hoursLimit.toString().padStart(2, '0')}:00
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
            style={{ width: `${(hoursUsed / hoursLimit) * 100}%` }}
          />
        </div>
      </div>
    </div>
  );
}