import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
}

interface ExportOptions {
  title?: string;
  agentName?: string;
  expertise?: string[];
}

export async function exportChatToPDF(messages: ChatMessage[], options: ExportOptions = {}): Promise<void> {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const margin = 20;

  // Header
  doc.setFontSize(20);
  doc.text(options.title || 'Chat Transcript', pageWidth / 2, 20, { align: 'center' });

  // Agent Info
  if (options.agentName) {
    doc.setFontSize(12);
    doc.text(`Agent: ${options.agentName}`, margin, 35);
    if (options.expertise) {
      doc.text(`Expertise: ${options.expertise.join(', ')}`, margin, 42);
    }
  }

  // Date
  doc.text(`Date: ${new Date().toLocaleDateString()}`, margin, 50);

  let yPosition = 60;

  // Chat Messages
  messages.forEach((message) => {
    const role = message.role === 'user' ? 'You' : options.agentName || 'Agent';
    const time = message.timestamp.toLocaleTimeString();
    
    // Add new page if needed
    if (yPosition > doc.internal.pageSize.height - 20) {
      doc.addPage();
      yPosition = 20;
    }

    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.text(`${role} - ${time}`, margin, yPosition);
    yPosition += 7;

    doc.setFontSize(11);
    doc.setTextColor(0);
    const lines = doc.splitTextToSize(message.content, pageWidth - (margin * 2));
    lines.forEach(line => {
      if (yPosition > doc.internal.pageSize.height - 20) {
        doc.addPage();
        yPosition = 20;
      }
      doc.text(line, margin, yPosition);
      yPosition += 7;
    });

    yPosition += 5;
  });

  // Footer
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(10);
    doc.text(
      `Page ${i} of ${pageCount}`,
      pageWidth / 2,
      doc.internal.pageSize.height - 10,
      { align: 'center' }
    );
  }

  // Download
  doc.save(`chat-transcript-${new Date().toISOString().split('T')[0]}.pdf`);
}

export function exportChatToWord(messages: ChatMessage[], options: ExportOptions = {}): void {
  // Create Word document content in HTML format
  const content = `
    <html xmlns:w="urn:schemas-microsoft-com:office:word">
      <head>
        <meta charset="utf-8">
        <title>Chat Transcript</title>
        <style>
          body { font-family: 'Calibri', sans-serif; }
          .header { margin-bottom: 20px; }
          .message { margin: 10px 0; }
          .user { background: #f0f0f0; }
          .assistant { background: #e8f0fe; }
          .meta { color: #666; font-size: 0.9em; }
        </style>
      </head>
      <body>
        <div class="header">
          <h1>${options.title || 'Chat Transcript'}</h1>
          ${options.agentName ? `<p>Agent: ${options.agentName}</p>` : ''}
          ${options.expertise ? `<p>Expertise: ${options.expertise.join(', ')}</p>` : ''}
          <p>Date: ${new Date().toLocaleDateString()}</p>
        </div>

        ${messages.map(message => `
          <div class="message ${message.role}">
            <div class="meta">
              ${message.role === 'user' ? 'You' : options.agentName || 'Agent'} - 
              ${message.timestamp.toLocaleTimeString()}
            </div>
            <p>${message.content}</p>
          </div>
        `).join('')}
      </body>
    </html>
  `;

  // Create blob and download
  const blob = new Blob([content], { type: 'application/msword' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `chat-transcript-${new Date().toISOString().split('T')[0]}.doc`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}