import { openai } from './transcription/openai';
import { detectLanguage } from './transcription/languageDetection';
import { analyzeContent } from './transcription/analysis';
import { SUPPORTED_AUDIO_FORMATS, FILE_SIZE_THRESHOLD, SUPPORTED_LANGUAGES } from '../constants';
import { getAudioDuration } from './transcription/audioUtils';
import { transcribeLargeFile } from './transcription/speechTextApi';
import { useUsageStore } from '../store/usageStore';
import type { Transcription, TranscriptionRequest } from '../types';

export async function uploadAudioForTranscription(
  request: TranscriptionRequest
): Promise<Transcription> {
  const { file, onProgress, useLargeFileMode } = request;
  
  try {
    onProgress?.(0, 'Preparing file');
    const startTime = Date.now();

    if (!SUPPORTED_AUDIO_FORMATS.includes(file.type)) {
      throw new Error(`Unsupported file format. Supported formats: ${SUPPORTED_AUDIO_FORMATS.map(format => format.split('/')[1]).join(', ')}`);
    }

    // Get audio duration
    onProgress?.(5, 'Analyzing audio file');
    const duration = await getAudioDuration(file);

    // Check file size and switch to appropriate service
    if (useLargeFileMode || file.size > FILE_SIZE_THRESHOLD) {
      onProgress?.(10, 'Switching to large file processing');
      return await transcribeLargeFile(file, (progress, stage) => onProgress?.(progress, stage));
    }

    // Use OpenAI Whisper for smaller files
    onProgress?.(10, 'Using OpenAI Whisper');

    // Detect language
    onProgress?.(15, 'Detecting language');
    const languageInfo = await detectLanguage(file);

    if (!SUPPORTED_LANGUAGES.includes(languageInfo.code)) {
      throw new Error(`Language ${languageInfo.name} is not supported`);
    }

    // Transcribe with OpenAI
    onProgress?.(30, 'Transcribing audio');
    const transcriptionResponse = await openai.audio.transcriptions.create({
      file,
      model: 'whisper-1',
      language: languageInfo.code,
      response_format: 'text',
      temperature: 0.2,
      prompt: languageInfo.code === 'nl' ? 'Dit is een Nederlandse opname. Transcribeer het alstublieft nauwkeurig.' : undefined
    });

    // Analyze content
    onProgress?.(70, 'Analyzing content');
    const analysis = await analyzeContent(transcriptionResponse, languageInfo.code);

    // Create transcription object
    const endTime = Date.now();
    const processingTime = (endTime - startTime) / 1000;

    const transcription: Transcription = {
      id: crypto.randomUUID(),
      userId: 'current-user',
      status: 'completed',
      originalFileName: file.name,
      originalFile: file,
      duration,
      createdAt: new Date().toISOString(),
      completedAt: new Date().toISOString(),
      transcript: transcriptionResponse,
      language: languageInfo.code,
      languageInfo,
      model: file.size > FILE_SIZE_THRESHOLD ? 'speechtext' : 'whisper',
      progress: 100,
      processingTime,
      wordCount: transcriptionResponse.split(/\s+/).length,
      ...analysis
    };

    // Save to localStorage
    const storedTranscriptions = localStorage.getItem('transcriptions');
    const transcriptions = storedTranscriptions ? JSON.parse(storedTranscriptions) : [];
    transcriptions.unshift(transcription);
    localStorage.setItem('transcriptions', JSON.stringify(transcriptions));

    // Update usage stats
    const { incrementTranscription } = useUsageStore.getState();
    incrementTranscription(duration);

    onProgress?.(100, 'Complete');
    return transcription;

  } catch (error) {
    console.error('Transcription error:', error);
    throw error instanceof Error ? error : new Error('Failed to transcribe audio');
  }
}

export async function getAllTranscriptions(): Promise<Transcription[]> {
  const stored = localStorage.getItem('transcriptions');
  return stored ? JSON.parse(stored) : [];
}

export async function deleteTranscription(id: string): Promise<void> {
  const stored = localStorage.getItem('transcriptions');
  if (!stored) return;

  const transcriptions = JSON.parse(stored);
  const filtered = transcriptions.filter((t: Transcription) => t.id !== id);
  localStorage.setItem('transcriptions', JSON.stringify(filtered));
}

export async function retryTranscription(id: string): Promise<Transcription> {
  const stored = localStorage.getItem('transcriptions');
  if (!stored) throw new Error('Transcription not found');

  const transcriptions = JSON.parse(stored);
  const transcription = transcriptions.find((t: Transcription) => t.id === id);
  if (!transcription) throw new Error('Transcription not found');

  if (!transcription.originalFile) {
    throw new Error('Original file not available for retry');
  }

  // Remove failed transcription
  await deleteTranscription(id);

  // Retry with original file
  return await uploadAudioForTranscription({
    file: transcription.originalFile,
    onProgress: (progress, stage) => {
      console.log(`Retry progress: ${progress}% - ${stage}`);
    }
  });
}