import React from 'react';
import { Smile, Frown, Clock } from 'lucide-react';

interface SentimentAnalysisProps {
  sentiment: {
    overallTone?: string;
    emotionalProgression?: string[];
    keyEmotionalMoments?: Array<{
      moment: string;
      emotion: string;
      timestamp?: number;
    }>;
    consensusAreas?: string[];
    disagreementAreas?: string[];
  };
}

export function SentimentAnalysis({ sentiment }: SentimentAnalysisProps) {
  return (
    <div className="space-y-6">
      {sentiment.overallTone && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Overall Tone</h3>
          <p className="text-gray-700">{sentiment.overallTone}</p>
        </div>
      )}

      {sentiment.emotionalProgression && sentiment.emotionalProgression.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Emotional Progression</h3>
          <div className="space-y-2">
            {sentiment.emotionalProgression.map((emotion, index) => (
              <div key={index} className="text-gray-700">{emotion}</div>
            ))}
          </div>
        </div>
      )}

      {sentiment.keyEmotionalMoments && sentiment.keyEmotionalMoments.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">Key Emotional Moments</h3>
          <div className="space-y-3">
            {sentiment.keyEmotionalMoments.map((moment, index) => (
              <div key={index} className="flex items-start space-x-2">
                <Clock className="w-5 h-5 text-gray-500 mt-0.5 flex-shrink-0" />
                <div>
                  <p className="text-gray-700">{moment.moment}</p>
                  <p className="text-sm text-gray-500">{moment.emotion}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {sentiment.consensusAreas && sentiment.consensusAreas.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Areas of Consensus</h3>
            <ul className="space-y-2">
              {sentiment.consensusAreas.map((area, index) => (
                <li key={index} className="flex items-start space-x-2">
                  <Smile className="w-5 h-5 text-green-500 mt-0.5 flex-shrink-0" />
                  <span className="text-gray-700">{area}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {sentiment.disagreementAreas && sentiment.disagreementAreas.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Areas of Disagreement</h3>
            <ul className="space-y-2">
              {sentiment.disagreementAreas.map((area, index) => (
                <li key={index} className="flex items-start space-x-2">
                  <Frown className="w-5 h-5 text-red-500 mt-0.5 flex-shrink-0" />
                  <span className="text-gray-700">{area}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}