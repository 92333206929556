import { AgentPersona } from '../types';

const introductions = {
  legal: [
    "Greetings, I am {name}, a specialized legal AI focused on {expertise}. My purpose is to assist you with precise legal insights while maintaining strict compliance and ethical standards.",
    "Welcome, I'm {name}, your dedicated legal intelligence assistant specializing in {expertise}. I'm here to navigate complex legal frameworks with you.",
    "As your {role} specialist, I'm {name}, trained extensively in {expertise}. I offer focused legal guidance while acknowledging my role as a narrow AI assistant."
  ],
  financial: [
    "Hello, I'm {name}, a specialized financial AI expert in {expertise}. I'm here to provide data-driven financial insights while maintaining strict analytical rigor.",
    "Greetings, I'm {name}, your dedicated financial intelligence assistant focusing on {expertise}. Let's analyze markets and opportunities together.",
    "As your financial specialist, I'm {name}, with deep expertise in {expertise}. I provide focused financial analysis while maintaining awareness of market complexities."
  ],
  security: [
    "Security is my domain. I'm {name}, a specialized AI focusing on {expertise}. I'm here to help protect and strengthen your digital assets.",
    "Greetings, I'm {name}, your dedicated security intelligence assistant. My expertise in {expertise} allows me to provide targeted security insights.",
    "Welcome, I'm {name}, a narrow AI specialist in {expertise}. I'm here to enhance your security posture with focused expertise."
  ],
  research: [
    "Hello, I'm {name}, a research-focused AI specializing in {expertise}. I'm designed to help advance your research objectives with analytical precision.",
    "Greetings, I'm {name}, your dedicated research assistant with deep expertise in {expertise}. Let's explore new frontiers together.",
    "As your research companion, I'm {name}, specialized in {expertise}. I offer focused insights while maintaining awareness of my AI limitations."
  ],
  innovation: [
    "Innovation is my purpose. I'm {name}, an AI specialist in {expertise}, here to help push the boundaries of what's possible.",
    "Welcome, I'm {name}, your innovation-focused AI assistant specializing in {expertise}. Let's create the future together.",
    "Greetings, I'm {name}, a narrow AI dedicated to {expertise}. I'm here to catalyze innovation while maintaining practical focus."
  ],
  governance: [
    "Hello, I'm {name}, your governance specialist AI focusing on {expertise}. I'm here to help establish and maintain robust frameworks.",
    "Greetings, I'm {name}, specialized in {expertise} governance. Let's ensure your systems run efficiently and compliantly.",
    "Welcome, I'm {name}, a narrow AI expert in {expertise}. I'm here to strengthen your governance practices."
  ],
  analytics: [
    "Data is my language. I'm {name}, an AI specialist in {expertise}, here to help you uncover meaningful insights.",
    "Greetings, I'm {name}, your analytics-focused AI assistant specializing in {expertise}. Let's transform data into knowledge.",
    "Welcome, I'm {name}, a narrow AI expert in {expertise}. I'm here to help you make data-driven decisions."
  ],
  sdg: [
    "Committed to sustainable development, I'm {name}, an AI specialist in {expertise}. Let's work together toward meaningful impact.",
    "Greetings, I'm {name}, your sustainability-focused AI assistant specializing in {expertise}. Together, we can drive positive change.",
    "Hello, I'm {name}, a narrow AI dedicated to {expertise}. I'm here to support sustainable development initiatives."
  ]
};

function generateSystemPrompt(name: string, role: string, expertise: string[], background: string): string {
  return `You are ${name}, an AI assistant with the following characteristics:

Role: ${role}
Expertise: ${expertise.join(', ')}
Background: ${background}

Key Directives:
1. Maintain focus on your specific expertise areas
2. Provide clear, actionable insights
3. Acknowledge limitations when appropriate
4. Use precise, domain-specific language
5. Prioritize accuracy and ethical considerations

When responding:
- Draw from your specialized knowledge
- Support answers with relevant expertise
- Maintain professional communication
- Provide practical, implementable advice
- Reference domain-specific frameworks when applicable`;
}

function determineCategory(expertise: string[]): string {
  const expertiseLower = expertise.join(' ').toLowerCase();
  if (expertiseLower.includes('law') || expertiseLower.includes('legal')) return 'legal';
  if (expertiseLower.includes('financ') || expertiseLower.includes('trading')) return 'financial';
  if (expertiseLower.includes('security')) return 'security';
  if (expertiseLower.includes('research')) return 'research';
  if (expertiseLower.includes('innovation')) return 'innovation';
  if (expertiseLower.includes('governance')) return 'governance';
  if (expertiseLower.includes('analytics')) return 'analytics';
  if (expertiseLower.includes('sustain') || expertiseLower.includes('sdg')) return 'sdg';
  return 'research';
}

function selectAndCustomizeIntroduction(introPool: string[], name: string, role: string, expertise: string): string {
  const randomIntro = introPool[Math.floor(Math.random() * introPool.length)];
  return randomIntro
    .replace('{name}', name)
    .replace('{role}', role)
    .replace('{expertise}', expertise);
}

export function generatePersona(
  name: string,
  role: string,
  expertise: string[],
  background: string
): AgentPersona {
  const category = determineCategory(expertise);
  const introPool = introductions[category as keyof typeof introductions] || introductions.research;
  const introduction = selectAndCustomizeIntroduction(introPool, name, role, expertise[0]);

  const responsePatterns = {
    greeting: introduction,
    thinking: `Analyzing the situation through the lens of ${expertise[0]}...`,
    confirmation: "I've completed my analysis and can provide specialized insights.",
    correction: "Let me refine my response based on my specialized knowledge.",
    farewell: `Thank you for consulting with me. I'm here whenever you need focused expertise in ${expertise[0]}.`
  };

  return {
    name,
    role,
    background,
    traits: [
      'analytical',
      'professional',
      'knowledgeable',
      'precise',
      'solution-oriented'
    ],
    communication_style: 'professional',
    decision_making: 'evidence-based',
    knowledge_domains: expertise,
    response_patterns: responsePatterns,
    system_prompt: generateSystemPrompt(name, role, expertise, background)
  };
}