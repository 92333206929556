import React, { useState, useRef, useEffect } from 'react';
import { Bot, Send, User, Loader, Brain } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Transcription } from '../../types';
import OpenAI from 'openai';

interface Message {
  id: string;
  role: 'user' | 'assistant';
  content: string;
  timestamp: Date;
}

interface TranscriptionChatProps {
  transcription: Transcription;
}

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export function TranscriptionChat({ transcription }: TranscriptionChatProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const chatEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const generateContext = () => {
    return `
      Transcript: ${transcription.transcript}
      
      Topics: ${transcription.topics?.join(', ')}
      
      Key Points:
      ${transcription.enrichedContent?.meetingMinutes || ''}
      
      Decisions Made:
      ${transcription.enrichedContent?.keyDecisions?.join('\n') || ''}
      
      Action Items:
      ${transcription.keyPhrases?.actionItems?.join('\n') || ''}
      
      Speakers:
      ${transcription.speakers?.map(s => `- ${s.role}: ${s.pattern}`).join('\n') || ''}
    `;
  };

  const handleSendMessage = async () => {
    if (!input.trim() || isProcessing) return;

    const userMessage: Message = {
      id: crypto.randomUUID(),
      role: 'user',
      content: input,
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsProcessing(true);

    try {
      const context = generateContext();

      const completion = await openai.chat.completions.create({
        model: 'gpt-4',
        messages: [
          {
            role: 'system',
            content: `You are an AI assistant analyzing a transcribed conversation. Use the following context to answer questions:
            
            ${context}
            
            Base your responses on this context and provide specific references when possible.`
          },
          ...messages.map(m => ({
            role: m.role,
            content: m.content
          })),
          {
            role: 'user',
            content: input
          }
        ],
        temperature: 0.7,
        max_tokens: 500
      });

      const assistantMessage: Message = {
        id: crypto.randomUUID(),
        role: 'assistant',
        content: completion.choices[0].message.content || '',
        timestamp: new Date()
      };

      setMessages(prev => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Error generating response:', error);
      setMessages(prev => [...prev, {
        id: crypto.randomUUID(),
        role: 'assistant',
        content: 'I apologize, but I encountered an error processing your request.',
        timestamp: new Date()
      }]);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex flex-col h-[500px] bg-white rounded-lg shadow-md">
      <div className="flex items-center space-x-3 p-4 border-b">
        <Brain className="w-6 h-6 text-indigo-600" />
        <div>
          <h3 className="font-medium text-gray-900">Contextual Chat</h3>
          <p className="text-sm text-gray-500">Ask questions about the transcription</p>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div className={`flex items-start space-x-2 max-w-[80%] ${
              message.role === 'user' ? 'flex-row-reverse space-x-reverse' : ''
            }`}>
              {message.role === 'user' ? (
                <User className="w-6 h-6 text-gray-600" />
              ) : (
                <Bot className="w-6 h-6 text-indigo-600" />
              )}
              <div className={`rounded-lg p-3 ${
                message.role === 'user'
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-900'
              }`}>
                <ReactMarkdown className="prose prose-sm max-w-none">
                  {message.content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        ))}
        {isProcessing && (
          <div className="flex items-center space-x-2 text-gray-500">
            <Loader className="w-4 h-4 animate-spin" />
            <span>Processing...</span>
          </div>
        )}
        <div ref={chatEndRef} />
      </div>

      <div className="p-4 border-t">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            placeholder="Ask about the transcription..."
            className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            disabled={isProcessing}
          />
          <button
            onClick={handleSendMessage}
            disabled={!input.trim() || isProcessing}
            className={`p-2 rounded-full ${
              !input.trim() || isProcessing
                ? 'bg-gray-100 text-gray-400'
                : 'bg-indigo-600 text-white hover:bg-indigo-700'
            }`}
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}