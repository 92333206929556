import React, { useState } from 'react';
import { AgentCreator } from '../components/VirtualCoworker/AgentCreator';
import { AgentSwarm } from '../components/VirtualCoworker/AgentSwarm';
import { AgentLibrary } from '../components/VirtualCoworker/AgentLibrary';
import { AgentTemplates } from '../components/VirtualCoworker/AgentTemplates';
import { Tabs } from '../components/ui/Tabs';
import type { Template } from '../types';

export function VirtualCoworker() {
  const [activeTab, setActiveTab] = useState('templates');
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

  const tabs = [
    { id: 'templates', label: 'Agent Templates' },
    { id: 'create', label: 'Create Agent' },
    { id: 'library', label: 'Agent Library' },
    { id: 'swarm', label: 'Schedule Swarm' }
  ];

  const handleTemplateSelect = (template: Template) => {
    setSelectedTemplate(template);
    setActiveTab('create');
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-bold text-gray-900 mb-8">AI Assistant</h1>
      
      <div className="mb-6">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onChange={setActiveTab}
        />
      </div>

      {activeTab === 'templates' && (
        <AgentTemplates onTemplateSelect={handleTemplateSelect} />
      )}
      
      {activeTab === 'create' && (
        <AgentCreator 
          template={selectedTemplate}
          onSuccess={() => {
            setActiveTab('library');
            setSelectedTemplate(null);
          }}
        />
      )}
      
      {activeTab === 'library' && (
        <AgentLibrary
          selectedAgents={selectedAgents}
          onAgentSelectionChange={setSelectedAgents}
        />
      )}
      
      {activeTab === 'swarm' && (
        <AgentSwarm />
      )}
    </div>
  );
}