import React, { useState } from 'react';
import { 
  Search, 
  Brain, 
  BookOpen, 
  Target, 
  Shield, 
  Lightbulb, 
  Globe, 
  BarChart2, 
  Scale,
  Landmark,
  Heart
} from 'lucide-react';
import type { Template } from '../../types';
import { agentTemplates } from '../../data/agentTemplates';

interface AgentTemplatesProps {
  onTemplateSelect: (template: Template) => void;
}

const categories = [
  { id: 'sdg', label: 'SDG Goals', icon: Globe },
  { id: 'analytics', label: 'Analytics', icon: BarChart2 },
  { id: 'security', label: 'Security', icon: Shield },
  { id: 'research', label: 'Research', icon: BookOpen },
  { id: 'innovation', label: 'Innovation', icon: Lightbulb },
  { id: 'governance', label: 'Governance', icon: Target },
  { id: 'legal', label: 'Legal', icon: Scale },
  { id: 'financial', label: 'Financial', icon: Landmark },
  { id: 'healthcare', label: 'Healthcare', icon: Heart }
];

export function AgentTemplates({ onTemplateSelect }: AgentTemplatesProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const filteredTemplates = agentTemplates.filter(template => {
    if (!template || typeof template !== 'object') return false;
    
    const matchesCategory = !selectedCategory || template.category === selectedCategory;
    const searchTerm = searchQuery.toLowerCase();
    
    const matchesSearch = !searchTerm || 
      (template.name && template.name.toLowerCase().includes(searchTerm)) ||
      (template.description && template.description.toLowerCase().includes(searchTerm)) ||
      (Array.isArray(template.expertise) && template.expertise.some(exp => 
        exp && exp.toLowerCase().includes(searchTerm)
      ));

    return matchesCategory && matchesSearch;
  });

  const CategoryIcon = ({ category }: { category: typeof categories[0] }) => {
    const Icon = category.icon;
    return <Icon className="w-5 h-5" />;
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
          <input
            type="text"
            placeholder="Search templates..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
      </div>

      <div className="flex space-x-4 overflow-x-auto pb-2">
        {categories.map(category => (
          <button
            key={category.id}
            onClick={() => setSelectedCategory(selectedCategory === category.id ? null : category.id)}
            className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
              selectedCategory === category.id
                ? 'bg-indigo-100 text-indigo-700'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            <CategoryIcon category={category} />
            <span>{category.label}</span>
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredTemplates.map((template) => (
          <div
            key={template.id}
            className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center space-x-3 mb-4">
              <Brain className="w-6 h-6 text-indigo-600" />
              <h3 className="text-lg font-semibold text-gray-900">{template.name}</h3>
            </div>

            <p className="text-gray-600 mb-4">{template.description}</p>

            <div className="space-y-3">
              <div>
                <h4 className="text-sm font-medium text-gray-700">Expertise</h4>
                <div className="flex flex-wrap gap-2 mt-2">
                  {template.expertise?.map((exp, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-indigo-50 text-indigo-700 rounded-full text-sm"
                    >
                      {exp}
                    </span>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-700">Goals</h4>
                <ul className="mt-2 space-y-1">
                  {template.goals?.map((goal, index) => (
                    <li key={index} className="text-sm text-gray-600">
                      • {goal}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <button
              onClick={() => onTemplateSelect(template)}
              className="w-full mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
            >
              Use Template
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}