export const LANGUAGE_SAMPLE_SIZE = 1024 * 1024; // 1MB sample for language detection
export const SUPPORTED_LANGUAGES = ['nl', 'en', 'fr', 'de', 'es', 'it', 'pt'];
export const MAX_CHUNK_SIZE = 23 * 1024 * 1024; // 23MB for OpenAI API
export const FILE_SIZE_THRESHOLD = 23 * 1024 * 1024; // 23MB threshold for API selection
export const MAX_FILE_SIZE = 1024 * 1024 * 1024; // 1GB max for large files
export const MAX_PARALLEL_UPLOADS = 5;

export const SUPPORTED_AUDIO_FORMATS = [
  'audio/mp3',
  'audio/wav',
  'audio/m4a',
  'audio/ogg',
  'audio/flac',
  'audio/webm',
  'audio/mpeg'
];

export const LANGUAGE_INFO = {
  nl: { code: 'nl', name: 'Dutch', native: 'Nederlands' },
  en: { code: 'en', name: 'English', native: 'English' },
  fr: { code: 'fr', name: 'French', native: 'Français' },
  de: { code: 'de', name: 'German', native: 'Deutsch' },
  es: { code: 'es', name: 'Spanish', native: 'Español' },
  it: { code: 'it', name: 'Italian', native: 'Italiano' },
  pt: { code: 'pt', name: 'Portuguese', native: 'Português' }
};