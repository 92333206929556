import React, { useState, useRef, useEffect } from 'react';
import { Mic, MicOff, Send, Bot, User, Loader, Download } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Agent, Message } from '../../types';
import { useRealtimeAudio } from '../../hooks/useRealtimeAudio';
import { streamAgentResponse } from '../../services/dialogueService';
import { exportChatToPDF, exportChatToWord } from '../../utils/chatExporter';

interface AgentChatProps {
  agent: Agent;
  onComplete?: () => void;
}

export function AgentChat({ agent, onComplete }: AgentChatProps) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputText, setInputText] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const chatEndRef = useRef<HTMLDivElement>(null);
  
  const {
    isListening,
    transcript,
    error,
    startListening,
    stopListening
  } = useRealtimeAudio();

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    // Add initial greeting
    if (messages.length === 0 && agent.persona.response_patterns.greeting) {
      setMessages([{
        id: crypto.randomUUID(),
        role: 'assistant',
        content: agent.persona.response_patterns.greeting,
        timestamp: new Date()
      }]);
    }
  }, [agent]);

  const handleSendMessage = async (content: string) => {
    if (!content.trim() || isProcessing) return;

    setIsProcessing(true);
    setInputText('');

    try {
      // Add user message
      const userMessage: Message = {
        id: crypto.randomUUID(),
        role: 'user',
        content,
        timestamp: new Date()
      };
      setMessages(prev => [...prev, userMessage]);

      // Add thinking message
      const thinkingMessage: Message = {
        id: crypto.randomUUID(),
        role: 'assistant',
        content: agent.persona.response_patterns.thinking,
        timestamp: new Date()
      };
      setMessages(prev => [...prev, thinkingMessage]);

      // Stream agent response
      let responseContent = '';
      const responseMessage: Message = {
        id: crypto.randomUUID(),
        role: 'assistant',
        content: '',
        timestamp: new Date()
      };

      // Remove thinking message and add response message
      setMessages(prev => [...prev.slice(0, -1), responseMessage]);

      for await (const chunk of streamAgentResponse(agent, content, messages)) {
        responseContent += chunk;
        setMessages(prev => prev.map(msg =>
          msg.id === responseMessage.id
            ? { ...msg, content: responseContent }
            : msg
        ));
      }

    } catch (error) {
      console.error('Error processing message:', error);
      setMessages(prev => [...prev, {
        id: crypto.randomUUID(),
        role: 'assistant',
        content: 'I apologize, but I encountered an error processing your request.',
        timestamp: new Date()
      }]);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleExport = async (format: 'pdf' | 'word') => {
    const exportOptions = {
      title: 'Chat with ' + agent.name,
      agentName: agent.name,
      expertise: agent.expertise
    };

    if (format === 'pdf') {
      await exportChatToPDF(messages, exportOptions);
    } else {
      exportChatToWord(messages, exportOptions);
    }
  };

  const toggleListening = async () => {
    if (isListening) {
      await stopListening();
      if (transcript) {
        await handleSendMessage(transcript);
      }
    } else {
      await startListening();
    }
  };

  return (
    <div className="flex flex-col h-[600px] bg-white rounded-lg shadow-md">
      <div className="flex items-center justify-between p-4 border-b">
        <div className="flex items-center space-x-3">
          <Bot className="w-6 h-6 text-indigo-600" />
          <div>
            <h3 className="font-medium text-gray-900">{agent.name}</h3>
            <p className="text-sm text-gray-500">{agent.expertise.join(' • ')}</p>
          </div>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={() => handleExport('pdf')}
            className="p-2 text-gray-600 hover:text-indigo-600"
            title="Export as PDF"
          >
            <Download className="w-5 h-5" />
          </button>
          <button
            onClick={() => handleExport('word')}
            className="p-2 text-gray-600 hover:text-indigo-600"
            title="Export as Word"
          >
            <Download className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div className={`flex items-start space-x-2 max-w-[80%] ${
              message.role === 'user' ? 'flex-row-reverse space-x-reverse' : ''
            }`}>
              {message.role === 'user' ? (
                <User className="w-6 h-6 text-gray-600" />
              ) : (
                <Bot className="w-6 h-6 text-indigo-600" />
              )}
              <div className={`rounded-lg p-3 ${
                message.role === 'user'
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-900'
              }`}>
                <ReactMarkdown className="prose prose-sm max-w-none">
                  {message.content}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        ))}
        {transcript && (
          <div className="flex justify-end">
            <div className="bg-gray-100 text-gray-600 rounded-lg p-3 max-w-[80%] italic">
              {transcript}...
            </div>
          </div>
        )}
        <div ref={chatEndRef} />
      </div>

      <div className="p-4 border-t">
        <div className="flex items-center space-x-2">
          <button
            onClick={toggleListening}
            disabled={isProcessing}
            className={`p-2 rounded-full transition-colors ${
              isListening
                ? 'bg-red-100 text-red-600 hover:bg-red-200'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            } ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isListening ? <MicOff className="w-5 h-5" /> : <Mic className="w-5 h-5" />}
          </button>
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage(inputText);
              }
            }}
            placeholder="Type your message..."
            className="flex-1 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            disabled={isProcessing || isListening}
          />
          <button
            onClick={() => handleSendMessage(inputText)}
            disabled={!inputText.trim() || isProcessing}
            className={`p-2 rounded-full ${
              !inputText.trim() || isProcessing
                ? 'bg-gray-100 text-gray-400'
                : 'bg-indigo-600 text-white hover:bg-indigo-700'
            }`}
          >
            {isProcessing ? (
              <Loader className="w-5 h-5 animate-spin" />
            ) : (
              <Send className="w-5 h-5" />
            )}
          </button>
        </div>
        {error && (
          <div className="mt-2 text-sm text-red-600">
            {error}
          </div>
        )}
      </div>
    </div>
  );
}