import React, { useState, useCallback, useEffect } from 'react';
import { Brain, Upload, Book, Save } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { createAgent } from '../../services/agentService';
import { generateAgentName } from '../../utils/nameGenerator';
import type { Template } from '../../types';

interface AgentCreatorProps {
  template: Template | null;
  onSuccess: () => void;
}

export function AgentCreator({ template, onSuccess }: AgentCreatorProps) {
  const [profile, setProfile] = useState({
    name: '',
    expertise: [''],
    personality: '',
    background: '',
    goals: [''],
    model: 'gpt4-o',
    temperature: 0.8,
    maxTokens: 4096
  });

  const [knowledgeFiles, setKnowledgeFiles] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (template) {
      setProfile({
        name: generateAgentName(),
        expertise: template.expertise || [''],
        personality: template.personality || '',
        background: template.background || '',
        goals: template.goals || [''],
        model: 'gpt4-o',
        temperature: 0.8,
        maxTokens: 4096
      });
    }
  }, [template]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setKnowledgeFiles(prev => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'text/plain': ['.txt'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    maxSize: 50 * 1024 * 1024 // 50MB
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      if (!profile.name.trim()) {
        throw new Error('Name is required');
      }

      if (!profile.expertise.some(exp => exp.trim())) {
        throw new Error('At least one expertise area is required');
      }

      if (!profile.goals.some(goal => goal.trim())) {
        throw new Error('At least one goal is required');
      }

      const formData = new FormData();
      formData.append('profile', JSON.stringify({
        ...profile,
        expertise: profile.expertise.filter(exp => exp.trim()),
        goals: profile.goals.filter(goal => goal.trim())
      }));

      knowledgeFiles.forEach(file => {
        formData.append('knowledge', file);
      });

      await createAgent(formData);
      onSuccess();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to create agent');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center space-x-2 mb-6">
        <Brain className="w-6 h-6 text-indigo-600" />
        <h2 className="text-xl font-semibold text-gray-900">
          {template ? `Create Agent from ${template.name}` : 'Create New Agent'}
        </h2>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 text-red-600 rounded-lg">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
          <input
            type="text"
            value={profile.name}
            onChange={e => setProfile({ ...profile, name: e.target.value })}
            className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Expertise</label>
          {profile.expertise.map((exp, index) => (
            <div key={index} className="flex space-x-2 mb-2">
              <input
                type="text"
                value={exp}
                onChange={e => {
                  const newExpertise = [...profile.expertise];
                  newExpertise[index] = e.target.value;
                  setProfile({ ...profile, expertise: newExpertise });
                }}
                className="flex-1 px-3 py-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="Enter an area of expertise"
                required
              />
              {index === profile.expertise.length - 1 ? (
                <button
                  type="button"
                  onClick={() => setProfile({ ...profile, expertise: [...profile.expertise, ''] })}
                  className="px-3 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                >
                  +
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    const newExpertise = profile.expertise.filter((_, i) => i !== index);
                    setProfile({ ...profile, expertise: newExpertise });
                  }}
                  className="px-3 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  -
                </button>
              )}
            </div>
          ))}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Background</label>
          <textarea
            value={profile.background}
            onChange={e => setProfile({ ...profile, background: e.target.value })}
            className="w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            rows={3}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Goals</label>
          {profile.goals.map((goal, index) => (
            <div key={index} className="flex space-x-2 mb-2">
              <input
                type="text"
                value={goal}
                onChange={e => {
                  const newGoals = [...profile.goals];
                  newGoals[index] = e.target.value;
                  setProfile({ ...profile, goals: newGoals });
                }}
                className="flex-1 px-3 py-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                placeholder="Enter a goal"
                required
              />
              {index === profile.goals.length - 1 ? (
                <button
                  type="button"
                  onClick={() => setProfile({ ...profile, goals: [...profile.goals, ''] })}
                  className="px-3 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                >
                  +
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => {
                    const newGoals = profile.goals.filter((_, i) => i !== index);
                    setProfile({ ...profile, goals: newGoals });
                  }}
                  className="px-3 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  -
                </button>
              )}
            </div>
          ))}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Knowledge Base</label>
          <div
            {...getRootProps()}
            className={`border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors
              ${isDragActive ? 'border-indigo-500 bg-indigo-50' : 'border-gray-300 hover:border-indigo-400'}`}
          >
            <input {...getInputProps()} />
            <Upload className="w-8 h-8 text-indigo-500 mx-auto mb-2" />
            <p className="text-gray-600">
              {isDragActive ? 'Drop files here' : 'Drag and drop files, or click to select'}
            </p>
            <p className="text-sm text-gray-500 mt-1">
              Supports PDF, TXT, DOC, DOCX (max 50MB per file)
            </p>
          </div>
          {knowledgeFiles.length > 0 && (
            <ul className="mt-4 space-y-2">
              {knowledgeFiles.map((file, index) => (
                <li key={index} className="flex items-center justify-between text-sm text-gray-600">
                  <span>{file.name}</span>
                  <button
                    type="button"
                    onClick={() => setKnowledgeFiles(prev => prev.filter((_, i) => i !== index))}
                    className="text-red-600 hover:text-red-700"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full flex items-center justify-center px-4 py-2 rounded-lg transition-colors ${
            isSubmitting
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-indigo-600 hover:bg-indigo-700'
          } text-white`}
        >
          <Save className="w-5 h-5 mr-2" />
          {isSubmitting ? 'Creating Agent...' : 'Create Agent'}
        </button>
      </form>
    </div>
  );
}