import React from 'react';
import { Book, FileAudio, Brain, Bot, Download, Zap, Shield, Users } from 'lucide-react';

export function Documentation() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Documentation</h1>

      <div className="prose prose-lg max-w-none space-y-12">
        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
            <FileAudio className="w-6 h-6 mr-2 text-indigo-600" />
            The Evolution of Meeting Intelligence
          </h2>
          
          <p>
            In today's fast-paced business environment, every conversation holds valuable insights waiting to be unlocked. Our AI-powered audio intelligence platform transforms traditional meetings into rich sources of actionable knowledge, enabling teams to capture, understand, and leverage every important detail.
          </p>

          <p>
            Imagine walking into a meeting knowing that every strategic insight, action item, and key decision will be automatically captured and analyzed. Our platform makes this possible by combining advanced speech recognition with sophisticated AI analysis, ensuring that no valuable information is lost and every conversation contributes to your organization's knowledge base.
          </p>

          <div className="bg-indigo-50 p-6 rounded-lg my-8">
            <h3 className="text-xl font-semibold mb-4">Key Capabilities</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="text-indigo-600 mr-2">•</span>
                <span>
                  <strong>Intelligent Transcription:</strong> Beyond simple speech-to-text, our system understands context, identifies speakers, and captures the nuances of conversation.
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-indigo-600 mr-2">•</span>
                <span>
                  <strong>Real-time Analysis:</strong> Watch as key topics, action items, and insights emerge during your conversation, enabling immediate response and decision-making.
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-indigo-600 mr-2">•</span>
                <span>
                  <strong>Knowledge Synthesis:</strong> Automatically connect discussions with your organization's knowledge base, surfacing relevant insights and historical context.
                </span>
              </li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
            <Brain className="w-6 h-6 mr-2 text-indigo-600" />
            Augmented Intelligence: The Future of Decision Making
          </h2>

          <p>
            In an era where decisions must be made faster and with greater precision than ever before, AI augmentation becomes not just an advantage, but a necessity. Our platform empowers decision-makers with AI-enhanced capabilities while maintaining human insight and judgment at the core of the process.
          </p>

          <p>
            Through sophisticated pattern recognition and predictive analytics, our system helps identify trends, risks, and opportunities that might otherwise go unnoticed. This combination of human expertise and AI capabilities creates a powerful synergy that drives better, faster, and more informed decision-making.
          </p>

          <div className="bg-green-50 p-6 rounded-lg my-8">
            <h3 className="text-xl font-semibold mb-4">Impact Areas</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="text-green-600 mr-2">•</span>
                <span>
                  <strong>Strategic Planning:</strong> Enhance long-term planning with AI-powered scenario analysis and trend prediction.
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-green-600 mr-2">•</span>
                <span>
                  <strong>Risk Management:</strong> Identify and assess potential risks with greater accuracy and foresight.
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-green-600 mr-2">•</span>
                <span>
                  <strong>Innovation:</strong> Accelerate innovation by connecting insights across domains and identifying new opportunities.
                </span>
              </li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
            <Bot className="w-6 h-6 mr-2 text-indigo-600" />
            Virtual Co-workers: Redefining Team Collaboration
          </h2>

          <p>
            The concept of virtual co-workers represents a paradigm shift in how teams operate and collaborate. Our AI agents are not mere tools but specialized team members that bring unique expertise and capabilities to your organization.
          </p>

          <p>
            These virtual co-workers excel in processing vast amounts of information, identifying patterns, and providing insights that complement human expertise. They adapt to your organization's context, learn from interactions, and become increasingly valuable members of your team over time.
          </p>

          <div className="bg-purple-50 p-6 rounded-lg my-8">
            <h3 className="text-xl font-semibold mb-4">Collaboration Features</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="text-purple-600 mr-2">•</span>
                <span>
                  <strong>Specialized Expertise:</strong> Access domain-specific knowledge and insights on demand.
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-purple-600 mr-2">•</span>
                <span>
                  <strong>Continuous Learning:</strong> Agents that evolve and improve through interaction with your team.
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-purple-600 mr-2">•</span>
                <span>
                  <strong>Seamless Integration:</strong> Natural interaction that fits into existing workflows and processes.
                </span>
              </li>
            </ul>
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
            <Shield className="w-6 h-6 mr-2 text-indigo-600" />
            Enterprise-Grade Security and Compliance
          </h2>

          <p>
            Security and compliance are foundational elements of our platform. We understand that the conversations and decisions happening within your organization are sensitive and valuable assets that must be protected.
          </p>

          <div className="bg-blue-50 p-6 rounded-lg my-8">
            <h3 className="text-xl font-semibold mb-4">Security Framework</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <span className="text-blue-600 mr-2">•</span>
                <span>
                  <strong>Data Protection:</strong> End-to-end encryption and secure data handling throughout the platform.
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 mr-2">•</span>
                <span>
                  <strong>Compliance:</strong> Built-in controls and features to maintain regulatory compliance.
                </span>
              </li>
              <li className="flex items-start">
                <span className="text-blue-600 mr-2">•</span>
                <span>
                  <strong>Privacy:</strong> Advanced privacy controls and data governance features.
                </span>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}