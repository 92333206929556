import React from 'react';
import { Brain, BarChart2, Search, MessageSquare, ArrowLeft } from 'lucide-react';
import { ContentEnrichment } from './ContentEnrichment';
import { SentimentAnalysis } from './SentimentAnalysis';
import { TopicModeling } from './TopicModeling';
import { TranscriptionChat } from '../Dashboard/TranscriptionChat';
import type { Transcription } from '../../types';

interface TranscriptAnalysisProps {
  transcription: Transcription;
  onBack: () => void;
  onExportWord: () => void;
  onExportPDF: () => void;
}

export function TranscriptAnalysis({ 
  transcription,
  onBack,
  onExportWord,
  onExportPDF
}: TranscriptAnalysisProps) {
  const [activeTab, setActiveTab] = React.useState<'content' | 'sentiment' | 'topics' | 'chat'>('content');

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={onBack}
          className="flex items-center space-x-2 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="w-5 h-5" />
          <span>Back to Transcriptions</span>
        </button>
        <h2 className="text-xl font-bold text-gray-900">
          {transcription.originalFileName}
        </h2>
      </div>

      {/* Tabs */}
      <div className="flex space-x-4 border-b">
        <button
          onClick={() => setActiveTab('content')}
          className={`flex items-center space-x-2 px-4 py-2 ${
            activeTab === 'content' ? 'border-b-2 border-indigo-600 text-indigo-600' : 'text-gray-600'
          }`}
        >
          <Brain className="w-5 h-5" />
          <span>Content Analysis</span>
        </button>
        <button
          onClick={() => setActiveTab('sentiment')}
          className={`flex items-center space-x-2 px-4 py-2 ${
            activeTab === 'sentiment' ? 'border-b-2 border-indigo-600 text-indigo-600' : 'text-gray-600'
          }`}
        >
          <BarChart2 className="w-5 h-5" />
          <span>Sentiment</span>
        </button>
        <button
          onClick={() => setActiveTab('topics')}
          className={`flex items-center space-x-2 px-4 py-2 ${
            activeTab === 'topics' ? 'border-b-2 border-indigo-600 text-indigo-600' : 'text-gray-600'
          }`}
        >
          <Search className="w-5 h-5" />
          <span>Topics</span>
        </button>
        <button
          onClick={() => setActiveTab('chat')}
          className={`flex items-center space-x-2 px-4 py-2 ${
            activeTab === 'chat' ? 'border-b-2 border-indigo-600 text-indigo-600' : 'text-gray-600'
          }`}
        >
          <MessageSquare className="w-5 h-5" />
          <span>Chat</span>
        </button>
      </div>

      {/* Export buttons */}
      <div className="flex justify-end space-x-4">
        <button
          onClick={onExportWord}
          className="px-4 py-2 text-indigo-600 border border-indigo-600 rounded-lg hover:bg-indigo-50"
        >
          Export to Word
        </button>
        <button
          onClick={onExportPDF}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
        >
          Download PDF
        </button>
      </div>

      {/* Content */}
      {activeTab === 'content' && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Content Enrichment</h2>
            <ContentEnrichment enrichedContent={transcription.enrichedContent || {}} />
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-bold text-gray-900 mb-4">Key Insights</h2>
            <div className="space-y-4">
              {transcription.keyPhrases?.actionItems?.map((item, index) => (
                <div key={index} className="flex items-start space-x-2">
                  <span className="text-indigo-600">•</span>
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {activeTab === 'sentiment' && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">Sentiment Analysis</h2>
          <SentimentAnalysis sentiment={transcription.sentiment || {}} />
        </div>
      )}

      {activeTab === 'topics' && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">Topic Analysis</h2>
          <TopicModeling topics={transcription.topics || []} />
        </div>
      )}

      {activeTab === 'chat' && (
        <div className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">Chat with Content</h2>
          <TranscriptionChat transcription={transcription} />
        </div>
      )}
    </div>
  );
}