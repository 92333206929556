import { TranscriptionJob } from '../../types';

const SPEECHTEXT_API_URL = 'https://api.speechtext.ai';
const LARGE_FILE_SIZE = 35 * 1024 * 1024; // 35MB threshold

interface SpeechTextResponse {
  status: 'processing' | 'finished' | 'failed';
  id?: string;
  results?: {
    transcript: string;
    word_time_offsets: Array<{
      word: string;
      start_time: number;
      end_time: number;
      confidence: number;
    }>;
    summary?: string;
  };
}

export async function transcribeLargeFile(
  file: File,
  onProgress: (progress: number, stage: string) => void
): Promise<TranscriptionJob> {
  try {
    // Start transcription
    onProgress(10, 'Initializing large file transcription');
    const formData = new FormData();
    formData.append('file', file);

    const initResponse = await fetch(`${SPEECHTEXT_API_URL}/recognize`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: file,
      params: {
        key: process.env.VITE_SPEECHTEXT_API_KEY,
        language: 'nl-NL', // Default to Dutch
        punctuation: true,
        format: file.name.split('.').pop()
      }
    });

    const { id: taskId } = await initResponse.json();
    if (!taskId) throw new Error('Failed to initialize transcription');

    onProgress(30, 'Processing audio file');

    // Poll for results
    const result = await pollTranscriptionStatus(taskId, onProgress);
    
    onProgress(90, 'Finalizing transcription');

    return {
      id: taskId,
      status: 'completed',
      originalFileName: file.name,
      transcript: result.results?.transcript || '',
      duration: calculateDurationFromWordOffsets(result.results?.word_time_offsets),
      language: 'nl',
      model: 'speechtext',
      progress: 100,
      createdAt: new Date().toISOString(),
      completedAt: new Date().toISOString(),
      wordCount: result.results?.transcript.split(/\s+/).length || 0
    };

  } catch (error) {
    console.error('SpeechText API error:', error);
    throw error;
  }
}

async function pollTranscriptionStatus(
  taskId: string, 
  onProgress: (progress: number, stage: string) => void
): Promise<SpeechTextResponse> {
  const maxAttempts = 60;
  let attempts = 0;

  while (attempts < maxAttempts) {
    const response = await fetch(`${SPEECHTEXT_API_URL}/results`, {
      params: {
        key: process.env.VITE_SPEECHTEXT_API_KEY,
        task: taskId,
        summary: true,
        summary_size: 15
      }
    });

    const result: SpeechTextResponse = await response.json();

    if (result.status === 'finished') {
      return result;
    }

    if (result.status === 'failed') {
      throw new Error('Transcription failed');
    }

    attempts++;
    const progress = Math.min(30 + (attempts * 1), 85);
    onProgress(progress, 'Processing audio file');
    await new Promise(resolve => setTimeout(resolve, 15000)); // Wait 15 seconds
  }

  throw new Error('Transcription timed out');
}

function calculateDurationFromWordOffsets(
  wordOffsets?: Array<{ end_time: number }>
): number {
  if (!wordOffsets?.length) return 0;
  return Math.ceil(wordOffsets[wordOffsets.length - 1].end_time);
}