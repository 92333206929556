import React from 'react';

export function Security() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-4">Security Policy</h1>
      <p className="text-sm text-gray-600 mb-8">Effective Date: 28/08/2024</p>

      <div className="prose prose-lg max-w-none space-y-8">
        <section>
          <h2 className="text-2xl font-bold text-gray-900">Introduction</h2>
          <p>CyberResilience.Pro, AI Media Concept Publishers Division, operating Audioscribe.today, is committed to safeguarding the integrity, confidentiality, and availability of all data under Our control. This Security Policy ("Policy") details Our approach to data security, risk management, and compliance with applicable laws, including the General Data Protection Regulation (GDPR) and Dutch privacy regulations. By using Our Services, You agree to the terms and practices outlined in this Policy.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">1. Scope and Purpose</h2>
          <p>This Policy applies to all data processing, storage, and transmission activities related to Audioscribe.today, including data collected from Users, employees, and third-party providers. The purpose of this Policy is to prevent unauthorized access, disclosure, alteration, and destruction of data, ensuring that data is handled securely throughout its lifecycle.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">2. Governance and Accountability</h2>
          <p>We have established a Data Protection and Security Team responsible for overseeing and implementing this Policy, monitoring compliance, and addressing any data security concerns. This team ensures adherence to security standards and compliance with GDPR, as well as internal and external audits, to continually evaluate and improve security practices.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">3. Security Controls and Measures</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Access Control:</strong> Access to sensitive data is strictly limited to authorized personnel only, based on job roles and responsibilities.</li>
            <li><strong>Data Encryption:</strong> Data is encrypted in transit and at rest using industry-standard encryption protocols.</li>
            <li><strong>Network Security:</strong> We maintain firewalls, intrusion detection systems (IDS), and intrusion prevention systems (IPS).</li>
            <li><strong>Physical Security:</strong> Our data centers are hosted in the EU-West Region with strict access controls.</li>
            <li><strong>Regular Security Assessments:</strong> We conduct frequent security audits and vulnerability assessments.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">4. Data Protection and Privacy</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Data Minimization:</strong> We collect only necessary data for Services and compliance.</li>
            <li><strong>Pseudonymization:</strong> Personal data is pseudonymized where possible.</li>
            <li><strong>Employee Training:</strong> Mandatory security and privacy training for all staff.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">5. Incident Response and Data Breach Management</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Incident Detection:</strong> Continuous monitoring and immediate reporting protocols.</li>
            <li><strong>Containment:</strong> Defined procedures for breach containment and remediation.</li>
            <li><strong>Notification:</strong> Prompt notification to affected parties and authorities.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">6. Risk Management</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Risk Assessments:</strong> Regular evaluation of threats and vulnerabilities.</li>
            <li><strong>Continuous Improvement:</strong> Ongoing adaptation to emerging threats.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">7. Third-Party Security</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Data Processing Agreements:</strong> Required for all third-party providers.</li>
            <li><strong>Security Assessments:</strong> Regular provider security evaluations.</li>
            <li><strong>Access Limitation:</strong> Strict data access controls for providers.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">8. Secure Development</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Coding Standards:</strong> Secure development practices and regular reviews.</li>
            <li><strong>Change Management:</strong> Documented process for updates and patches.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">9. Monitoring and Auditing</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li><strong>Log Management:</strong> Comprehensive activity logging and review.</li>
            <li><strong>Audit Trails:</strong> Detailed tracking of all system activities.</li>
            <li><strong>Regular Audits:</strong> Internal and external security assessments.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">10. User Responsibilities</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>Use strong, unique passwords</li>
            <li>Report security concerns immediately</li>
            <li>Be cautious of phishing attempts</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">11. Policy Review</h2>
          <p>This Security Policy is reviewed periodically to ensure continued compliance with applicable laws and best practices. Updates are effective immediately upon posting.</p>
        </section>

        <section>
          <h2 className="text-2xl font-bold text-gray-900">12. Contact Information</h2>
          <p>For security-related inquiries:</p>
          <p className="font-medium mt-2">Email: security@audioscribe.today</p>
          <p className="font-medium">Mailing Address: CyberResilience.Pro, AI Media Concept Publishers Division</p>
        </section>
      </div>
    </div>
  );
}