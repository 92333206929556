import React from 'react';
import { Bot, Brain, Zap, Lock, FileAudio, Sparkles } from 'lucide-react';

export function Features() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Evolving AI-Powered Features
        </h1>
        <p className="text-xl text-gray-600">
          Our platform utilizes self-coding agentic technologies, continuously evolving to provide enhanced functionality and capabilities.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <Bot className="w-8 h-8 text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Adaptive AI Agents</h3>
          <p className="text-gray-600">Self-evolving AI agents that learn and adapt to your specific needs and communication patterns.</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <FileAudio className="w-8 h-8 text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Advanced Transcription</h3>
          <p className="text-gray-600">High-accuracy transcription with automatic language detection and speaker identification.</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <Brain className="w-8 h-8 text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Knowledge Synthesis</h3>
          <p className="text-gray-600">Intelligent analysis and summarization of transcribed content with actionable insights.</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <Lock className="w-8 h-8 text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Enterprise Security</h3>
          <p className="text-gray-600">GDPR-compliant data handling with end-to-end encryption and secure data processing.</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <Zap className="w-8 h-8 text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Real-time Processing</h3>
          <p className="text-gray-600">Live transcription and analysis capabilities for immediate insights during meetings.</p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <Sparkles className="w-8 h-8 text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Evolving Features</h3>
          <p className="text-gray-600">Self-improving functionality through continuous learning and adaptation.</p>
        </div>
      </div>

      <div className="mt-16 bg-indigo-50 p-8 rounded-lg">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Self-Coding Agentic Technology</h2>
        <p className="text-gray-600 mb-4">
          Our platform leverages cutting-edge self-coding agentic technologies that enable continuous evolution and improvement of features. This means our system:
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          <li>Automatically adapts to user needs and preferences</li>
          <li>Self-optimizes for improved performance</li>
          <li>Develops new capabilities based on usage patterns</li>
          <li>Enhances existing features through machine learning</li>
        </ul>
      </div>
    </div>
  );
}