import { useState, useEffect, useRef } from 'react';
import { RealtimeAudioService } from '../services/realtimeAudioService';

export function useRealtimeAudio() {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [error, setError] = useState<string | null>(null);
  const audioServiceRef = useRef<RealtimeAudioService | null>(null);

  useEffect(() => {
    audioServiceRef.current = new RealtimeAudioService(
      (text) => {
        setTranscript(prev => prev + ' ' + text);
      },
      (error) => {
        console.error('Audio service error:', error);
        setError(error);
        setIsListening(false);
      },
      (listening) => setIsListening(listening)
    );

    return () => {
      if (audioServiceRef.current) {
        audioServiceRef.current.stopListening();
      }
    };
  }, []);

  const startListening = async () => {
    setError(null);
    setTranscript('');
    try {
      await audioServiceRef.current?.startListening();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to start listening');
    }
  };

  const stopListening = async () => {
    try {
      await audioServiceRef.current?.stopListening();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to stop listening');
    }
  };

  return {
    isListening,
    transcript,
    error,
    startListening,
    stopListening
  };
}