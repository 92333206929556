import React, { useState } from 'react';
import { 
  FileAudio, Clock, Check, AlertCircle, Download, FileText, 
  Brain, Trash2, RefreshCw
} from 'lucide-react';
import { Transcription } from '../../types';
import { formatDuration } from '../../services/transcription/audioUtils';
import { generatePDF } from '../../utils/pdfGenerator';
import { exportToWord } from '../../utils/wordExporter';
import { TranscriptAnalysis } from '../Analysis/TranscriptAnalysis';

interface TranscriptionListProps {
  transcriptions: Transcription[];
  onDelete?: (id: string) => void;
  onRetry?: (id: string) => void;
}

export function TranscriptionList({ transcriptions, onDelete, onRetry }: TranscriptionListProps) {
  const [selectedTranscript, setSelectedTranscript] = useState<string | null>(null);
  const [processingPDF, setProcessingPDF] = useState<Record<string, boolean>>({});

  const handleDownloadPDF = async (transcription: Transcription) => {
    setProcessingPDF(prev => ({ ...prev, [transcription.id]: true }));
    try {
      await generatePDF(transcription);
    } finally {
      setProcessingPDF(prev => ({ ...prev, [transcription.id]: false }));
    }
  };

  const handleExportWord = async (transcription: Transcription) => {
    try {
      await exportToWord(transcription, 'User');
    } catch (error) {
      console.error('Failed to export Word document:', error);
    }
  };

  if (selectedTranscript) {
    const transcription = transcriptions.find(t => t.id === selectedTranscript);
    if (!transcription) return null;

    return (
      <TranscriptAnalysis
        transcription={transcription}
        onBack={() => setSelectedTranscript(null)}
        onExportWord={() => handleExportWord(transcription)}
        onExportPDF={() => handleDownloadPDF(transcription)}
      />
    );
  }

  return (
    <div className="space-y-4">
      {transcriptions.map((transcription) => (
        <div key={transcription.id} className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-3">
              <FileAudio className="w-6 h-6 text-indigo-600" />
              <div>
                <h3 className="font-medium text-gray-900">
                  {transcription.originalFileName || 'Unnamed Recording'}
                </h3>
                <div className="flex items-center space-x-4 text-sm text-gray-500">
                  <span>Duration: {formatDuration(transcription.duration)}</span>
                  {transcription.wordCount && (
                    <span>Words: {transcription.wordCount.toLocaleString()}</span>
                  )}
                  {transcription.language && (
                    <span>Language: {transcription.language.toUpperCase()}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center space-x-2">
              {transcription.status === 'completed' && <Check className="w-5 h-5 text-green-500" />}
              {transcription.status === 'processing' && <Clock className="w-5 h-5 text-blue-500 animate-spin" />}
              {transcription.status === 'failed' && <AlertCircle className="w-5 h-5 text-red-500" />}
              <span className="text-sm capitalize text-gray-600">
                {transcription.status}
              </span>
            </div>
          </div>

          {transcription.status === 'processing' && transcription.progress && (
            <div className="mb-4">
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
                  style={{ width: `${transcription.progress}%` }}
                />
              </div>
              <p className="text-sm text-gray-500 mt-1">
                Processing: {transcription.progress}%
              </p>
            </div>
          )}

          {transcription.status === 'completed' && (
            <>
              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Transcript Preview</h4>
                <p className="text-sm text-gray-600 line-clamp-3">
                  {transcription.transcript}
                </p>
              </div>

              <div className="mt-4 flex items-center space-x-3">
                <button
                  onClick={() => handleDownloadPDF(transcription)}
                  disabled={processingPDF[transcription.id]}
                  className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  <Download className="w-4 h-4" />
                  <span>{processingPDF[transcription.id] ? 'Generating...' : 'Download PDF'}</span>
                </button>

                <button
                  onClick={() => handleExportWord(transcription)}
                  className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  <FileText className="w-4 h-4" />
                  <span>Export to Word</span>
                </button>

                <button
                  onClick={() => setSelectedTranscript(transcription.id)}
                  className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  <Brain className="w-4 h-4" />
                  <span>Analyze</span>
                </button>

                {onDelete && (
                  <button
                    onClick={() => onDelete(transcription.id)}
                    className="flex items-center space-x-2 px-4 py-2 border border-red-300 text-red-600 rounded-lg hover:bg-red-50"
                  >
                    <Trash2 className="w-4 h-4" />
                    <span>Delete</span>
                  </button>
                )}
              </div>
            </>
          )}

          {transcription.status === 'failed' && (
            <div className="mt-4">
              <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-4">
                {transcription.error || 'Transcription failed'}
              </div>
              {onRetry && (
                <button
                  onClick={() => onRetry(transcription.id)}
                  className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  <RefreshCw className="w-4 h-4" />
                  <span>Retry Transcription</span>
                </button>
              )}
            </div>
          )}
        </div>
      ))}

      {transcriptions.length === 0 && (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <FileAudio className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No transcriptions yet</h3>
          <p className="text-gray-500">Upload an audio file to get started</p>
        </div>
      )}
    </div>
  );
}