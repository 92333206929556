import React from 'react';
import { UsageStats } from '../components/Dashboard/UsageStats';
import { FileUpload } from '../components/Dashboard/FileUpload';
import { TranscriptionList } from '../components/Dashboard/TranscriptionList';
import { useTranscriptions } from '../hooks/useTranscriptions';
import { useAuthStore } from '../store/authStore';
import { Navigate } from 'react-router-dom';

export function Dashboard() {
  const { user } = useAuthStore();
  const { 
    transcriptions, 
    isLoading, 
    uploadAudio, 
    deleteTranscription,
    retryTranscription
  } = useTranscriptions();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-2xl font-bold text-gray-900 mb-8">Dashboard</h1>
      
      <UsageStats />

      <div className="space-y-8">
        <div>
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Upload Audio</h2>
          <FileUpload onTranscriptionComplete={(transcription) => {
            // Transcription will be automatically added through React Query
          }} />
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Transcriptions</h2>
          {isLoading ? (
            <div className="flex justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
            </div>
          ) : (
            <TranscriptionList 
              transcriptions={transcriptions}
              onDelete={deleteTranscription}
              onRetry={retryTranscription}
            />
          )}
        </div>
      </div>
    </div>
  );
}