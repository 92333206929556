import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { 
  uploadAudioForTranscription,
  deleteTranscription,
  retryTranscription,
  getAllTranscriptions
} from '../services/transcriptionService';
import type { Transcription } from '../types';

export function useTranscriptions() {
  const queryClient = useQueryClient();

  const { data: transcriptions = [], isLoading, error } = useQuery<Transcription[]>({
    queryKey: ['transcriptions'],
    queryFn: getAllTranscriptions,
    staleTime: 1000 * 60 // 1 minute
  });

  const uploadMutation = useMutation({
    mutationFn: uploadAudioForTranscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transcriptions'] });
    }
  });

  const deleteMutation = useMutation({
    mutationFn: deleteTranscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transcriptions'] });
    }
  });

  const retryMutation = useMutation({
    mutationFn: retryTranscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['transcriptions'] });
    }
  });

  return {
    transcriptions,
    isLoading,
    error,
    uploadAudio: uploadMutation.mutate,
    isUploading: uploadMutation.isPending,
    deleteTranscription: deleteMutation.mutate,
    isDeleting: deleteMutation.isPending,
    retryTranscription: retryMutation.mutate,
    isRetrying: retryMutation.isPending,
    refetch: queryClient.invalidateQueries
  };
}