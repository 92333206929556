import React, { useState } from 'react';
import { useAuthStore } from '../store/authStore';
import { User, Settings, Clock, FileAudio, Brain, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { TranscriptionList } from '../components/Dashboard/TranscriptionList';
import { useTranscriptions } from '../hooks/useTranscriptions';

export function Account() {
  const { user } = useAuthStore();
  const { transcriptions } = useTranscriptions();
  const [activeTab, setActiveTab] = useState<'overview' | 'subscription' | 'agents' | 'transcriptions'>('overview');

  if (!user) return null;

  const getPlanColor = (plan: string) => {
    switch (plan) {
      case 'free': return 'text-gray-600';
      case 'basic': return 'text-blue-600';
      case 'pro': return 'text-purple-600';
      case 'enterprise': return 'text-indigo-600';
      default: return 'text-gray-600';
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Account Settings</h1>
        <Link
          to="/pricing"
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Upgrade Plan
        </Link>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* Sidebar */}
        <div className="space-y-2">
          <button
            onClick={() => setActiveTab('overview')}
            className={`w-full text-left px-4 py-2 rounded-lg flex items-center justify-between ${
              activeTab === 'overview' ? 'bg-indigo-50 text-indigo-700' : 'hover:bg-gray-50'
            }`}
          >
            <span className="flex items-center">
              <User className="w-5 h-5 mr-2" />
              Overview
            </span>
            <ChevronRight className="w-4 h-4" />
          </button>
          
          <button
            onClick={() => setActiveTab('subscription')}
            className={`w-full text-left px-4 py-2 rounded-lg flex items-center justify-between ${
              activeTab === 'subscription' ? 'bg-indigo-50 text-indigo-700' : 'hover:bg-gray-50'
            }`}
          >
            <span className="flex items-center">
              <Settings className="w-5 h-5 mr-2" />
              Subscription
            </span>
            <ChevronRight className="w-4 h-4" />
          </button>

          <button
            onClick={() => setActiveTab('agents')}
            className={`w-full text-left px-4 py-2 rounded-lg flex items-center justify-between ${
              activeTab === 'agents' ? 'bg-indigo-50 text-indigo-700' : 'hover:bg-gray-50'
            }`}
          >
            <span className="flex items-center">
              <Brain className="w-5 h-5 mr-2" />
              My Agents
            </span>
            <ChevronRight className="w-4 h-4" />
          </button>

          <button
            onClick={() => setActiveTab('transcriptions')}
            className={`w-full text-left px-4 py-2 rounded-lg flex items-center justify-between ${
              activeTab === 'transcriptions' ? 'bg-indigo-50 text-indigo-700' : 'hover:bg-gray-50'
            }`}
          >
            <span className="flex items-center">
              <FileAudio className="w-5 h-5 mr-2" />
              Transcriptions
            </span>
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>

        {/* Main Content */}
        <div className="md:col-span-3">
          {activeTab === 'overview' && (
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Account Overview</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm text-gray-600">Name</label>
                  <p className="font-medium text-gray-900">{user.name}</p>
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Email</label>
                  <p className="font-medium text-gray-900">{user.email}</p>
                </div>
                <div>
                  <label className="block text-sm text-gray-600">Current Plan</label>
                  <p className={`font-medium capitalize ${getPlanColor(user.plan)}`}>
                    {user.plan}
                  </p>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'subscription' && (
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">Subscription Details</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="font-medium text-gray-900">Current Plan</h3>
                  <p className={`text-lg font-semibold ${getPlanColor(user.plan)}`}>
                    {user.plan.charAt(0).toUpperCase() + user.plan.slice(1)}
                  </p>
                  <p className="text-sm text-gray-500">{user.billingCycle}</p>
                </div>

                <div>
                  <h3 className="font-medium text-gray-900 mb-2">Usage</h3>
                  <div className="space-y-3">
                    <div>
                      <div className="flex justify-between text-sm mb-1">
                        <span>Hours Used</span>
                        <span>{user.hoursUsed} / {user.hoursLimit} hours</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div
                          className="bg-indigo-600 h-2 rounded-full"
                          style={{ width: `${(user.hoursUsed / user.hoursLimit) * 100}%` }}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex justify-between text-sm mb-1">
                        <span>Transcriptions</span>
                        <span>{user.transcriptionsUsed} / {user.transcriptionsLimit}</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div
                          className="bg-indigo-600 h-2 rounded-full"
                          style={{ width: `${(user.transcriptionsUsed / user.transcriptionsLimit) * 100}%` }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-6 border-t">
                  <Link
                    to="/pricing"
                    className="block w-full text-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                  >
                    Upgrade Plan
                  </Link>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'agents' && (
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">My AI Agents</h2>
              {/* Add AgentList component here */}
            </div>
          )}

          {activeTab === 'transcriptions' && (
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">My Transcriptions</h2>
              <TranscriptionList transcriptions={transcriptions} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}