const prefixes = [
  'Quantum', 'Neural', 'Nexus', 'Synth', 'Cyber', 'Echo', 'Vector', 'Matrix',
  'Nova', 'Pulse', 'Vertex', 'Cipher', 'Prism', 'Atlas', 'Helix', 'Apex'
];

const roots = [
  'Mind', 'Core', 'Logic', 'Think', 'Sense', 'Learn', 'Solve', 'Guide',
  'Sage', 'Brain', 'Intel', 'Genius', 'Vision', 'Mentor', 'Expert', 'Oracle'
];

export function generateAgentName(): string {
  const prefix = prefixes[Math.floor(Math.random() * prefixes.length)];
  const root = roots[Math.floor(Math.random() * roots.length)];
  const number = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  
  // Ensure name only contains alphanumeric characters
  return `${prefix}${root}${number}`.replace(/[^a-zA-Z0-9]/g, '');
}

export function validateAgentName(name: string): boolean {
  // Only allow letters, numbers, and underscores
  return /^[a-zA-Z0-9_]+$/.test(name);
}