import { Template } from '../types';
import { sdgAgents } from './templates/sdgAgents';
import { legalAgents } from './templates/legalAgents';
import { financialAgents } from './templates/financialAgents';
import { analyticsAgents } from './templates/analyticsAgents';
import { securityAgents } from './templates/securityAgents';
import { researchAgents } from './templates/researchAgents';
import { innovationAgents } from './templates/innovationAgents';
import { governanceAgents } from './templates/governanceAgents';
import { healthcareAgents } from './templates/healthcareAgents';

export const agentTemplates: Template[] = [
  ...sdgAgents,
  ...legalAgents,
  ...financialAgents,
  ...analyticsAgents,
  ...securityAgents,
  ...researchAgents,
  ...innovationAgents,
  ...governanceAgents,
  ...healthcareAgents
];

export default agentTemplates;