import { openai } from './openai';
import { getLanguageInfo } from './languages';
import { LANGUAGE_SAMPLE_SIZE } from './constants';

export async function detectLanguage(file: File) {
  try {
    // Take a sample for language detection
    const sampleBlob = file.slice(0, LANGUAGE_SAMPLE_SIZE);
    const sampleFile = new File([sampleBlob], 'sample.wav', { type: file.type });
    
    // First attempt with Dutch hint
    const response = await openai.audio.transcriptions.create({
      file: sampleFile,
      model: 'whisper-1',
      response_format: 'verbose_json',
      temperature: 0.2,
      prompt: 'Dit is waarschijnlijk een Nederlandse opname. Detecteer de taal nauwkeurig.'
    });

    // Get detected language and sample text
    const detectedLanguage = response.language?.toLowerCase() || 'en';
    const detectedText = response.text?.toLowerCase() || '';

    // Dutch language detection heuristics
    const dutchKeywords = [
      'de', 'het', 'een', 'en', 'van', 'in', 'op', 'voor', 'met', 'bij',
      'aan', 'om', 'te', 'zo', 'dat', 'die', 'niet', 'wat', 'als', 'nog'
    ];

    const hasDutchWords = dutchKeywords.some(word => 
      detectedText.includes(` ${word} `) || 
      detectedText.startsWith(`${word} `) ||
      detectedText.endsWith(` ${word}`)
    );

    // If Dutch words are found or language is detected as Dutch
    if (hasDutchWords || detectedLanguage === 'nl') {
      return getLanguageInfo('nl');
    }

    return getLanguageInfo(detectedLanguage);
  } catch (error) {
    console.warn('Language detection failed:', error);
    // Default to Dutch if detection fails
    return getLanguageInfo('nl');
  }
}