import React, { useState } from 'react';
import { Calendar, Clock, Target, X } from 'lucide-react';
import { Agent } from '../../types';

interface AgentSwarmSchedulerProps {
  agent: Agent;
  onSchedule: (schedule: any) => void;
  onClose: () => void;
}

export function AgentSwarmScheduler({ agent, onSchedule, onClose }: AgentSwarmSchedulerProps) {
  const [schedule, setSchedule] = useState({
    startDate: new Date().toISOString().slice(0, 16),
    endDate: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString().slice(0, 16),
    task: '',
    searchQuery: '',
    frequency: 'hourly',
    maxResults: 10
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSchedule(schedule);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Schedule Swarm Deployment</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Task Description</label>
            <textarea
              value={schedule.task}
              onChange={(e) => setSchedule({ ...schedule, task: e.target.value })}
              className="w-full px-3 py-2 border rounded-md"
              rows={3}
              placeholder="e.g., Monitor and analyze news articles about artificial intelligence developments"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Search Query</label>
            <input
              type="text"
              value={schedule.searchQuery}
              onChange={(e) => setSchedule({ ...schedule, searchQuery: e.target.value })}
              className="w-full px-3 py-2 border rounded-md"
              placeholder="e.g., artificial intelligence latest developments"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Start Date & Time</label>
              <input
                type="datetime-local"
                value={schedule.startDate}
                onChange={(e) => setSchedule({ ...schedule, startDate: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
                min={new Date().toISOString().slice(0, 16)}
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">End Date & Time</label>
              <input
                type="datetime-local"
                value={schedule.endDate}
                onChange={(e) => setSchedule({ ...schedule, endDate: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
                min={schedule.startDate}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Update Frequency</label>
              <select
                value={schedule.frequency}
                onChange={(e) => setSchedule({ ...schedule, frequency: e.target.value })}
                className="w-full px-3 py-2 border rounded-md"
              >
                <option value="hourly">Every Hour</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Max Results per Run</label>
              <input
                type="number"
                value={schedule.maxResults}
                onChange={(e) => setSchedule({ ...schedule, maxResults: parseInt(e.target.value) })}
                className="w-full px-3 py-2 border rounded-md"
                min={1}
                max={100}
                required
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
            >
              Schedule Deployment
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}