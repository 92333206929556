import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, AlertCircle, ToggleLeft, ToggleRight } from 'lucide-react';
import { TranscriptionProgress } from './TranscriptionProgress';
import type { Transcription } from '../../types';
import { uploadAudioForTranscription } from '../../services/transcriptionService';
import { SUPPORTED_AUDIO_FORMATS } from '../../constants';

interface FileUploadProps {
  onTranscriptionComplete: (transcription: Transcription) => void;
}

export function FileUpload({ onTranscriptionComplete }: FileUploadProps) {
  const [uploadStatus, setUploadStatus] = useState<Record<string, {
    fileName: string;
    progress: number;
    stage: string;
    status: 'uploading' | 'processing' | 'completed' | 'failed';
    error?: string;
  }>>({});
  const [error, setError] = useState<string | null>(null);
  const [useLargeFileMode, setUseLargeFileMode] = useState(false);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setError(null);

    for (const file of acceptedFiles) {
      try {
        if (!file || !file.type) {
          throw new Error('Invalid file: File or file type is missing');
        }

        if (!SUPPORTED_AUDIO_FORMATS.includes(file.type)) {
          throw new Error(`File ${file.name} has unsupported format. Supported formats: MP3, WAV, M4A, FLAC, OGG, WebM`);
        }

        setUploadStatus(prev => ({
          ...prev,
          [file.name]: {
            fileName: file.name,
            progress: 0,
            stage: 'Preparing upload',
            status: 'uploading'
          }
        }));

        const transcription = await uploadAudioForTranscription({
          file,
          useLargeFileMode,
          onProgress: (progress, stage) => {
            setUploadStatus(prev => ({
              ...prev,
              [file.name]: {
                ...prev[file.name],
                progress,
                stage: stage || 'Processing',
                status: progress === 100 ? 'completed' : 'processing'
              }
            }));
          }
        });

        onTranscriptionComplete(transcription);

        setTimeout(() => {
          setUploadStatus(prev => {
            const { [file.name]: removed, ...rest } = prev;
            return rest;
          });
        }, 3000);

      } catch (error) {
        console.error('Upload error:', error);
        setUploadStatus(prev => ({
          ...prev,
          [file.name]: {
            fileName: file.name,
            progress: 0,
            stage: 'Failed',
            status: 'failed',
            error: error instanceof Error ? error.message : 'Upload failed'
          }
        }));
      }
    }
  }, [onTranscriptionComplete, useLargeFileMode]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: SUPPORTED_AUDIO_FORMATS.reduce((acc, format) => {
      acc[format] = [];
      return acc;
    }, {} as Record<string, string[]>),
    maxSize: useLargeFileMode ? 1024 * 1024 * 1024 : 23 * 1024 * 1024
  });

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setUseLargeFileMode(!useLargeFileMode)}
            className="flex items-center space-x-2 text-sm"
          >
            {useLargeFileMode ? (
              <ToggleRight className="w-6 h-6 text-indigo-600" />
            ) : (
              <ToggleLeft className="w-6 h-6 text-gray-400" />
            )}
            <span>Large file mode</span>
          </button>
          <span className="text-sm text-gray-500">
            {useLargeFileMode ? 'Enabled for files up to 1GB' : 'Standard mode (up to 23MB)'}
          </span>
        </div>
      </div>

      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
          ${isDragActive ? 'border-indigo-500 bg-indigo-50' : 'border-gray-300 hover:border-indigo-400'}`}
      >
        <input {...getInputProps()} />
        <Upload className="w-12 h-12 text-indigo-500 mx-auto mb-4" />
        <p className="text-lg text-gray-600">
          {isDragActive ? 'Drop your audio files here' : 'Drag and drop audio files here, or click to select files'}
        </p>
        <p className="text-sm text-gray-500 mt-2">
          Supports MP3, WAV, M4A, FLAC, OGG, and WebM (max {useLargeFileMode ? '1GB' : '23MB'} per file)
        </p>
      </div>

      {error && (
        <div className="flex items-center gap-2 text-red-600 bg-red-50 p-3 rounded-md">
          <AlertCircle className="w-5 h-5" />
          <p className="text-sm">{error}</p>
        </div>
      )}

      {Object.entries(uploadStatus).map(([fileName, status]) => (
        <TranscriptionProgress
          key={fileName}
          fileName={fileName}
          progress={status.progress}
          stage={status.stage}
          status={status.status}
          error={status.error}
        />
      ))}
    </div>
  );
}