import OpenAI from 'openai';
import { Agent, AgentProfile } from '../types';
import { generatePersona } from './personaGenerator';
import { VectorStore } from './vectorStore';
import { processDocuments } from './documentProcessor';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function createAgent(formData: FormData): Promise<Agent> {
  try {
    const profileData = formData.get('profile');
    if (!profileData) {
      throw new Error('Profile data is required');
    }

    const profile: AgentProfile = JSON.parse(profileData.toString());
    const agentId = `agent_${Date.now()}_${Math.random().toString(36).substring(2)}`;
    const persona = generatePersona(
      profile.name,
      profile.role || 'AI Assistant',
      profile.expertise || [],
      profile.background || ''
    );

    const agent: Agent = {
      id: agentId,
      name: profile.name,
      expertise: profile.expertise || [],
      personality: profile.personality || '',
      background: profile.background || '',
      goals: profile.goals || [],
      model: 'gpt-4-turbo-preview',
      temperature: 0.8,
      maxTokens: 4096,
      persona,
      knowledgeBase: [],
      systemPrompt: persona.system_prompt,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    // Process knowledge base files
    const knowledgeFiles = formData.getAll('knowledge') as File[];
    if (knowledgeFiles.length > 0) {
      try {
        const { embeddings, documents } = await processDocuments(knowledgeFiles);
        
        // Store embeddings in OpenAI
        for (const embedding of embeddings) {
          const response = await openai.embeddings.create({
            model: 'text-embedding-3-large',
            input: embedding.text,
            encoding_format: 'float'
          });

          const documentId = `${agentId}_${Date.now()}_${Math.random().toString(36).substring(2)}`;

          agent.knowledgeBase.push({
            id: documentId,
            name: embedding.fileName,
            type: embedding.fileType,
            size: embedding.fileSize,
            uploadedAt: new Date().toISOString(),
            embeddingId: response.data[0].embedding.toString()
          });
        }
      } catch (error) {
        console.error('Error processing documents:', error);
        throw new Error('Failed to process knowledge base documents');
      }
    }

    // Store agent data
    localStorage.setItem(`agent_${agent.id}`, JSON.stringify(agent));
    return agent;
  } catch (error) {
    console.error('Error creating agent:', error);
    throw new Error(error instanceof Error ? error.message : 'Failed to create agent');
  }
}

export async function mergeAgents(agent1Id: string, agent2Id: string): Promise<Agent> {
  try {
    const agent1Data = await getAgentById(agent1Id);
    const agent2Data = await getAgentById(agent2Id);

    if (!agent1Data || !agent2Data) {
      throw new Error('One or both agents not found');
    }

    // Merge basic properties
    const mergedName = `${agent1Data.name} + ${agent2Data.name}`;
    const mergedExpertise = [...new Set([...agent1Data.expertise, ...agent2Data.expertise])];
    const mergedGoals = [...new Set([...agent1Data.goals, ...agent2Data.goals])];
    const mergedBackground = `${agent1Data.background}\n\n${agent2Data.background}`;

    // Create merged profile
    const mergedProfile: AgentProfile = {
      name: mergedName,
      expertise: mergedExpertise,
      background: mergedBackground,
      goals: mergedGoals,
      personality: `${agent1Data.personality} and ${agent2Data.personality}`,
      role: 'Merged AI Assistant'
    };

    // Create new agent with merged profile
    const formData = new FormData();
    formData.append('profile', JSON.stringify(mergedProfile));

    // Create new agent
    const mergedAgent = await createAgent(formData);

    // Merge knowledge bases
    const combinedKnowledgeBase = [
      ...agent1Data.knowledgeBase,
      ...agent2Data.knowledgeBase
    ];

    // Update embeddings for merged knowledge base
    const updatedKnowledgeBase = await Promise.all(
      combinedKnowledgeBase.map(async (doc) => {
        // Create new embedding for merged context
        const response = await openai.embeddings.create({
          model: 'text-embedding-3-large',
          input: doc.name, // Use document name as context
          encoding_format: 'float'
        });

        return {
          ...doc,
          id: `${mergedAgent.id}_${Date.now()}_${Math.random().toString(36).substring(2)}`,
          embeddingId: response.data[0].embedding.toString()
        };
      })
    );

    // Update merged agent with combined knowledge base
    mergedAgent.knowledgeBase = updatedKnowledgeBase;
    
    // Save updated merged agent
    localStorage.setItem(`agent_${mergedAgent.id}`, JSON.stringify(mergedAgent));

    return mergedAgent;
  } catch (error) {
    console.error('Error merging agents:', error);
    throw new Error('Failed to merge agents');
  }
}

export async function getAgents(): Promise<Agent[]> {
  try {
    const agents: Agent[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith('agent_')) {
        const agentData = localStorage.getItem(key);
        if (agentData) {
          agents.push(JSON.parse(agentData));
        }
      }
    }
    return agents;
  } catch (error) {
    console.error('Error fetching agents:', error);
    throw new Error('Failed to fetch agents');
  }
}

export async function deleteAgent(id: string): Promise<void> {
  try {
    const agent = await getAgentById(id);
    if (agent) {
      // Delete embeddings from OpenAI
      for (const doc of agent.knowledgeBase) {
        if (doc.embeddingId) {
          // Note: OpenAI doesn't currently provide a direct way to delete embeddings
          // This is a placeholder for when such functionality becomes available
          console.log(`Embedding ${doc.embeddingId} would be deleted here`);
        }
      }
    }
    localStorage.removeItem(`agent_${id}`);
  } catch (error) {
    console.error('Error deleting agent:', error);
    throw new Error('Failed to delete agent');
  }
}

export async function getAgentById(id: string): Promise<Agent | null> {
  try {
    const agentData = localStorage.getItem(`agent_${id}`);
    return agentData ? JSON.parse(agentData) : null;
  } catch (error) {
    console.error('Error fetching agent:', error);
    throw new Error('Failed to fetch agent');
  }
}